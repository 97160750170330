// components/TermsOfService.jsx

import React from 'react';

const TermsOfService = () => {
  return (
    <div className="p-5 bg-white text-black rounded-lg shadow-md max-w-4xl mx-auto my-10">
      <h1 className="text-4xl font-bold mb-6 text-center">Terms of Service</h1>
      <p className="mb-4">
        Welcome to XnorY! By using our website and services, you agree to the following terms:
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">1. Acceptance</h2>
      <p className="mb-4 ml-5">
        By accessing our website, you agree to these terms. If you do not agree, please do not use our site.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">2. Intellectual Property</h2>
      <p className="mb-4 ml-5">
        All content on our site, including text, graphics, logos, and images, is owned by XnorY or used with permission. We utilize free and open-source images from various sources, but this does not affect our ownership of other content and materials on our site.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">3. Usage Restrictions</h2>
      <p className="mb-4 ml-5">
        Do not misuse our website. This includes copying content, harming the site, or violating laws.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">4. Liability</h2>
      <p className="mb-4 ml-5">
        XnorY is not liable for any issues arising from your use of our site. We are not responsible for indirect damages.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">5. Indemnification</h2>
      <p className="mb-4 ml-5">
        You agree to cover any costs or damages XnorY incurs due to your violation of these terms.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">6. Changes to Terms</h2>
      <p className="mb-4 ml-5">
        We may update these terms. Check back regularly to stay informed of any changes.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">7. Governing Law</h2>
      <p className="mb-4 ml-5">
        These terms are governed by the laws of Sri Lanka, and any disputes will be resolved in the courts of Sri Lanka.
      </p>
    </div>
  );
};

export default TermsOfService;
