"use client";
import React from 'react';

const VisionAboutUs = () => {
    return (
        <section
            className="w-screen overflow-hidden h-full common-padding"
            style={{
                background: 'linear-gradient(to bottom, #0E0E0E, #000000)' // Zinc to Black gradient
            }}
        >
            <div className="screen-max-width text-white z-50">
                <h2 className="text-7xl sm:text-7xl md:text-8xl lg:text-9xl font-bold mb-6 text-right bg-gradient-to-r from-green-300 via-blue to-purple-600 bg-clip-text text-transparent">
                    Our <span className="text-white">Vision</span>
                </h2>
                <p className="text-lg md:text-xl lg:text-2xl">
                    <span className="block p-3 mb-5 mt-5 text-3xl sm:text-3xl md:text-5xl lg:text-5xl text-left font-bold">Empowering Innovation,<br />Enabling Excellence</span>
                    <span className='block p-3 text-justify text-neutral-400'>
                        “Our vision is to be the catalyst for technological innovation and excellence. We strive to empower businesses of all sizes by delivering cutting-edge software solutions that drive growth, efficiency, and success. Through our unwavering commitment to quality and innovation, we aim to shape the future of technology and create a world where digital solutions seamlessly enhance everyday life and business operations.”
                    </span>
                </p>
            </div>
        </section>
    );
};

export default VisionAboutUs;
