import React from 'react';
import { Link } from 'react-router-dom';

// Define the TextGrid component
const TextGrid_Development = () => {
  // Sample data for each cell in the grid
  const services = [
    { id: 'desktop-applications', title: "Desktop Applications", description: "Develop robust and scalable desktop applications tailored to meet your business needs, enhancing efficiency and productivity." },
    { id: 'web-applications', title: "Web Applications", description: "Leverage our expertise to build responsive and user-friendly web applications that deliver seamless experiences across all devices." },
    { id: 'mobile-applications', title: "Mobile Applications", description: "Create innovative mobile applications that provide outstanding user experiences and drive business growth." },
    { id: 'embedded-systems', title: "Embedded Systems", description: "Design and develop cutting-edge embedded systems that integrate seamlessly with your hardware to deliver optimal performance." },
    { id: 'iot-solutions', title: "IoT Solutions", description: "Implement smart IoT solutions to connect and manage your devices, enabling real-time data analytics and automation." },
    { id: 'cloud-services', title: "Cloud Services", description: "Optimize your operations with our cloud services, offering scalable, secure, and cost-effective solutions for your business." }
  ];

  return (
    <section id="highlights" className="w-screen overflow-hidden h-full common-padding bg-black">
      <div className="screen-max-width">
        <h2 className="text-center mb-16 py-3 text-3xl sm:text-4xl md:text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-500 via-pink-500 to-red-500">Development Services</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {services.map((service, index) => (
            <div
              key={service.id}
              className="bg-black border border-neutral-500 p-6 rounded-md flex flex-col justify-between cursor-pointer text-white transition-transform transform hover:bg-neutral-800 hover:scale-105"
            >
              <div className="flex flex-col">
                <h3 className="text-xl sm:text-3xl font-bold mb-6">{service.title}</h3>
                <p className="text-sm sm:text-base mb-4 text-left">{service.description}</p>
              </div>
              <Link
                to={`/services-overview/${service.id}`}
                className="inline-block px-2 py-1 text-sm font-semibold text-neutral-100 bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 rounded-md shadow-md hover:opacity-80 transition-opacity self-end"
              >
                Learn more
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TextGrid_Development;
