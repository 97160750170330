// components/Footer.jsx

import React from 'react';
import { footerLinks, socialLinks } from '../constants'; // Import footer and social links
import { linkedin, x } from '../utils'; // Import SVG icon paths

// Map icon names to image paths
const iconComponents = {
  linkedin,
  x,
};

const Footer = () => {
  return (
    <footer className="bg-black text-white py-8 px-5 sm:px-10">
      <div className="screen-max-width flex flex-col md:flex-row md:justify-between">
        
        {/* Company Info Section */}
        <div className="mb-6 md:mb-0">
          <h2 className="text-2xl font-bold mb-2">XnorY</h2>
          <p className="text-neutral-300 text-sm mb-4">
            Innovating the future with cutting-edge technology & unparalleled solutions
          </p>
          <div className="flex space-x-4">
            {socialLinks.map((link) => (
              <a
                key={link.href}
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors duration-300 text-sm"
                aria-label={link.name}
              >
                <img src={iconComponents[link.icon]} alt={link.name} className="w-5 h-5" />
              </a>
            ))}
          </div>
        </div>
        
        {/* Footer Links Section */}
        <div className="flex flex-col md:flex-row md:space-x-8">
          {footerLinks.map((link) => (
            <div key={link.name} className="mb-6 md:mb-0">
              <ul>
                <li>
                  <a
                    href={link.href}
                    className="text-gray-400 hover:text-white transition-colors duration-300 text-sm mb-1 block"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.name}
                  </a>
                </li>
              </ul>
            </div>
          ))}
        </div>
        
      </div>

      <div className="bg-neutral-600 my-8 h-[1px] w-full" />
      
      <div className="text-center text-white text-xs">
        <p>Copyright &copy; {new Date().getFullYear()} XnorY. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
