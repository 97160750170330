"use client";
import React from 'react';
import { ethics } from '../utils';

const EthicsAboutUs = () => {
    return (
        <section id="highlights" className="w-screen overflow-hidden h-full common-padding bg-zinc">
            <div className="container screen-max-width mx-auto px-4 flex flex-col md:flex-row items-center">
                {/* Ethics Content */}
                <div className="md:w-1/2 flex flex-col items-start mb-8 md:mb-0">
                    <h2 className="text-7xl sm:text-7xl md:text-8xl lg:text-8xl font-bold mb-6 text-left text-white">
                        Our <span className=" bg-gradient-to-r from-green-300 via-blue to-purple-600 bg-clip-text text-transparent">Ethics</span>
                    </h2>
                    <p className="text-lg md:text-xl text-neutral-400 mb-6 mt-5">
                        We uphold the highest standards of integrity and professionalism in all our dealings. Our commitment to ethical practices is reflected in our transparency, accountability, and respect for the rights and dignity of individuals. We believe in fostering a culture of honesty and fairness that guides our interactions with clients, partners, and employees.
                    </p>
                    <ul className="list-disc list-inside pl-5 text-neutral-200">
                        <li className="text-lg md:text-xl">Integrity in all our dealings.</li>
                        <li className="text-lg md:text-xl">Transparency and accountability.</li>
                        <li className="text-lg md:text-xl">Respect for individual rights and dignity.</li>
                        <li className="text-lg md:text-xl">Commitment to fairness and honesty.</li>
                    </ul>
                </div>

                {/* Ethics Image */}
                <div className="md:w-1/2 flex justify-center">
                    <img src={ethics} alt="Ethics" className="w-full h-auto rounded-lg shadow-lg" />
                </div>
            </div>
        </section>
    );
};

export default EthicsAboutUs;
