import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Bento from '../components/Bento';
import Model from '../components/Model';
import Form from '../components/Form';
import Stories from '../components/Stories';
import Footer from '../components/Footer';
import Details from '../components/Partners';
import AppleCardsCarouselDemo from '../components/AppleCardsCarouselDemo';
import FeaturesSectionDemo from '../components/FeaturesSectionDemo';

import * as Sentry from '@sentry/react';

const App = () => {
  return (
    <main className="bg-black">
      <Navbar /> {/* Top Navugation Bar */}
      <Hero /> {/* Home page Hero Section */}
      <Model />  {/* Section that show the Completed projects, Engineers & Years of experience */}
      <Details/> {/* Section that shows Big Companies that we worked for */}
      <Bento /> {/* Bento box of that shows "Our Expertise" */}
      <AppleCardsCarouselDemo/> {/* Apple cards like feature for display about the company */}
      <FeaturesSectionDemo/> {/* Feature & Technologies that use in the Company */}
      <Stories /> {/* */}
      <Form /> {/* Contact Us Form */}
      <Footer /> {/* Bottom Footer */}
    </main>
  )
}

export default Sentry.withProfiler(App);
