"use client";
import React, { useState } from 'react';

const FAQItem = ({ question, answer, isLink }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="border-b border-gray-300 text-white py-4">
            <div
                className="flex justify-between items-center cursor-pointer"
                onClick={toggleOpen}
            >
                <h3 className="text-lg font-semibold text-neutral-100">{question}</h3>
                <span className="text-xl">{isOpen ? '-' : '+'}</span>
            </div>
            {isOpen && (
                <div className="mt-2 text-neutral-400 ml-5">
                    {isLink ? (
                        <div>
                            <a
                                href={answer}
                                className="text-blue-500 underline"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {answer}
                            </a>
                            <div className="mt-2 border border-gray-700 rounded-lg overflow-hidden">
                                <img
                                    src={`https://api.thumbnail.ws/api/6d45859c7e9bd8b4e32065dd9e582b78d23e65a3a6f3f83dd6909eab2dfb8d0b/thumbnail/get?url=${encodeURIComponent(answer)}`}
                                    alt="Preview"
                                    className="w-full h-auto"
                                />
                            </div>
                        </div>
                    ) : (
                        <p>{answer}</p>
                    )}
                </div>
            )}
        </div>
    );
};

const FAQAboutUs = () => {
    const faqData = [
        {
            question: "What services do you offer?",
            answer: "We offer custom software development services for local individual clients."
        },
        {
            question: "Where are you located?",
            answer: "We are located in Sri Lanka."
        },
        {
            question: "How many developers and engineers do you have?",
            answer: "We have a team of 10+ developers and engineers."
        },
        {
            question: "When was the company founded?",
            answer: "The company was founded in January 2020."
        },
        {
            question: "Who is the CEO of this company?",
            answer: "The CEO of XnorY is Yeheshan Thamara."
        },
        // {
        //     question: "Where can I find the CEO's LinkedIn profile?",
        //     answer: "https://lk.linkedin.com/in/yeheshan-thamara",
        //     isLink: true
        // },
        // Add more FAQs as needed
    ];

    return (
        <section id="highlights" className="w-screen overflow-hidden h-full common-padding bg-black">
            <div className="screen-max-width">
                <h2 className="text-5xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-white mb-5 text-right">
                    Frequently Asked <span className='bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 bg-clip-text text-transparent'><br/>Questions</span>
                </h2>
                <div className="bg-black rounded-lg shadow-lg p-5 ml-5">
                    {faqData.map((item, index) => (
                        <FAQItem key={index} {...item} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FAQAboutUs;
