"use client"; // For Next.js and React Server Components context

import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const PlusIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className={className}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
  </svg>
);

const BlogCard = ({ image, title, description, id, date }) => {
  return (
    <div className="border border-white/[0.2] flex flex-col items-start p-4 relative h-[30rem] hover:bg-neutral-800 transition cursor-pointer ease-in-out duration-300">
      {/* Top Left Plus Mark */}
      <PlusIcon className="absolute h-6 w-6 -top-3 -left-3 text-white" />
      
      {/* Bottom Left Plus Mark */}
      <PlusIcon className="absolute h-6 w-6 -bottom-3 -left-3 text-white" />
      
      {/* Top Right Plus Mark */}
      <PlusIcon className="absolute h-6 w-6 -top-3 -right-3 text-white" />
      
      {/* Bottom Right Plus Mark */}
      <PlusIcon className="absolute h-6 w-6 -bottom-3 -right-3 text-white" />

      {/* Image */}
      <img
        src={image}
        alt={title} // Updated alt text to be descriptive
        className="w-full h-72 object-cover rounded-md"
      />

      {/* Title */}
      <h2 className="text-lg font-semibold text-gray-50 mt-4">
        {title}
      </h2>

      {/* Description */}
      <p className="text-sm text-gray-50 mt-2">
        {description}
      </p>

      {/* Footer with Date and Read More Button */}
      <div className="flex justify-between items-center mt-auto w-full">
        {/* Post Date */}
        <p className="text-xs text-gray-400">
          {date}
        </p>

        {/* Read More Button */}
        <Link
          to={`/blog-overview/${id}`} // Use the id prop for the link
          className="bg-white rounded-3xl text-black px-4 py-2 hover:bg-blue-600 hover:text-white hover:bg-blue transition ease-in-out duration-300"
        >
          Read More
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
