import React from 'react';

const BlogPostView = ({ title, description, image, date, author, textColor }) => {
    return (
        <section className="common-padding -mt-16 md:-mt-24 -mb-32 md:-mb-48">
            <div className="screen-max-width text-white">
                {/* Image container */}
                <div className="relative w-full h-60 sm:h-72 md:h-96 mb-6 rounded-xl border border-neutral-600 overflow-hidden">
                    <img 
                        src={image} 
                        alt={title} 
                        className="absolute inset-0 w-full h-full object-cover"
                    />
                </div>
                <h1 className={`text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-3 text-right ${textColor}`}>{title}</h1>
                
                {/* Date and Author */}
                <div className="flex items-center justify-end mb-8">
                    <div className="text-sm sm:text-base text-gray-400 mr-4">{date}</div>
                    <div className="text-sm sm:text-base text-netral-200">by {author}</div>
                </div>
                
                {/* Description */}
                <div className="sm:text-lg md:text-xl text-left max-w-4xl mx-auto">
                    {description.map((paragraph, index) => (
                        <p key={index} className="mb-5">{paragraph}</p>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default BlogPostView;
