import React from 'react';

const HeroAboutUs = () => {
  // Define the inline SVG for the repeating text pattern
  const backgroundSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
      <style>
        .sans { font-family: Arial, sans-serif; }
      </style>
      <text x="0" y="445" font-size="600" class="sans" fill="white" opacity="0.07">X?</text>
    </svg>
  `;

return (
    <div className="relative flex items-start justify-center">
      {/* Background Text Pattern */}
      <div
        className="absolute inset-0 overflow-hidden z-0"
        style={{
          backgroundImage: `url('data:image/svg+xml;utf8,${encodeURIComponent(backgroundSvg)}')`,
          backgroundSize: 'auto 100px', // Adjust size as needed
          backgroundRepeat: 'repeat',
        }}
      >
      </div>

      <div className="relative flex flex-col md:flex-row z-10 mt-0 mb-0">
        <section id="highlights" className="w-screen overflow-hidden h-full common-padding">
          <div className="screen-max-width">
            {/* Welcome Message */}
            <div className="text-white text-center md:text-left p-5">
              <h1 className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl font-bold mb-9 justify-center text-left text-amber-400">
                Welcome to <br/><span className='text-white'>XnorY</span>
              </h1>
              <p className="text-lg sm:text-xl md:text-xl lg:text-2xl text-balance text-justify text-neutral-400">
                <span className="font-bold text-xl sm:text-2xl md:text-2xl lg:text-2xl">W</span>e are committed to delivering innovative and bespoke software solutions tailored to meet the unique needs of our clients. Our team of seasoned professionals is dedicated to excellence, ensuring that each project is executed with precision and care. Discover more about our story, our values, and the comprehensive range of services we provide.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HeroAboutUs;
