import React, { useState } from "react";
import { BentoGrid, BentoGridItem } from "./ui/bento-grid";
import {
  IconSatellite,
  IconTimeline,
  IconCloud,
  IconBrowser,
  IconPhone,
  IconDeviceDesktop,
  IconCpu2,
} from "@tabler/icons-react";
import { consulting, cloud, webImage, desktopImage, embeddedImage, mobileAppVideo, mobileAppImage, iotImage } from '../utils';

const Highlights = () => {
  const Skeleton = () => (
    <div className="flex flex-1 w-full h-full min-h-[6rem] rounded-xl bg-gradient-to-br from-neutral-200 dark:from-neutral-900 dark:to-neutral-800 to-neutral-100"></div>
  );
  const [mobileAppVideoLoaded, setMobileAppVideoLoaded] = useState(false);
  const items = [
    {
      title: "Desktop Applications",
      description: "Robust desktop applications.",
      header: <img src={desktopImage} alt="Desktop Applications" className="w-full h-full md:h-2/3 object-cover rounded-xl" />,
      icon: <IconDeviceDesktop className="h-4 w-4 text-neutral-500" />,
    },
    {
      title: "Web Applications",
      description: "Custom web applications.",
      header: <img src={webImage} alt="Web Applications" className="w-full h-full md:h-2/3 object-cover rounded-xl" />,
      icon: <IconBrowser className="h-4 w-4 text-neutral-500" />,
    },
    {
      title: "Mobile Applications",
      description: "Innovative mobile apps.",
      header: (
        <>
          {!mobileAppVideoLoaded && <img src={mobileAppImage} alt="Mobile Applications" className="w-full h-full md:h-2/3 object-cover rounded-xl" />}
          <video
            src={mobileAppVideo}
            className={`w-full h-2/3 object-cover rounded-xl ${mobileAppVideoLoaded ? '' : 'hidden'}`}
            autoPlay
            loop
            muted
            playsInline
            onLoadedData={() => setMobileAppVideoLoaded(true)}
          >
            Your browser does not support the video tag.
          </video>
        </>
      ),
      icon: <IconPhone className="h-4 w-4 text-neutral-500" />,
    },
    {
      title: "Embedded Systems",
      description: "High-performance embedded systems for various industries.",
      header: <img src={embeddedImage} alt="Embedded Systems" className="w-full h-full md:h-2/3 object-cover rounded-xl" />,
      icon: <IconCpu2 className="h-4 w-4 text-neutral-500" />,
      className: "md:col-span-2",
    },
    {
      title: "IoT Solutions",
      description: "IoT solutions for smart environments.",
      header: <img src={iotImage} alt="Embedded Systems" className="w-full h-full md:h-2/3 object-cover rounded-xl" />,
      icon: <IconSatellite className="h-4 w-4 text-neutral-500" />,
    },
    {
      title: "Cloud Services",
      description: "Scalable cloud services.",
      header: <img src={cloud} alt="Cloud Services" className="w-full h-full md:h-2/3 object-cover rounded-xl" />,
      icon: <IconCloud className="h-4 w-4 text-neutral-500" />,
    },
    {
      title: "Consulting Services",
      description: "Expert consulting to drive your digital transformation.",
      header: <img src={consulting} alt="Consulting Services" className="w-full h-full md:h-2/3 object-cover rounded-xl" />,
      icon: <IconTimeline className="h-4 w-4 text-neutral-500" />,
      className: "md:col-span-2",
    },
  ];

  return (
    <section id="highlights" className="w-screen overflow-hidden h-full common-padding bg-zinc">
      <div className="screen-max-width">
        <h1 className="relative z-10 text-6xl sm:text-7xl md:text-8xl lg:text-8xl text-center font-sans font-bold mb-10">
          <span className="text-white">Our</span>
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue via-indigo-400 to-purple-500"> Expertise</span>
        </h1>
        <BentoGrid className="max-w-4xl mx-auto my-1">
          {items.map((item, i) => (
            <BentoGridItem
              key={i}
              title={item.title}
              description={item.description}
              header={item.header}
              icon={item.icon}
              className={i === 3 || i === 6 ? "md:col-span-2" : ""}
            />
          ))}
        </BentoGrid>
      </div>
    </section>
  );
}

export default Highlights;
