"use client";

import { FlipWords } from "./ui/flip-words";
import { styleVideo } from '../utils';
import React from 'react';

const Hero = () => {
  const words = ["expertise", "guidance", "commitment", "innovation", "passion", "creativity", "dedication", "excellence", "leadership", "collaboration", "insight"];

  return (
    <div className="w-full flex items-center justify-center bg-black relative">
      <section className="w-full md:w-7/12 flex flex-col md:flex-row common-padding relative">
        <div className="flex items-center justify-center w-full p-5 text-center z-10">
          <h1 className="text-black dark:text-white">
            <span className="block text-6xl sm:text-8xl md:text-9xl lg:text-9xl font-bold mt-1 leading-none whitespace-nowrap">
              Your <span className="bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 text-transparent bg-clip-text">vision</span>,
            </span>
            <span className="block text-3xl text-white sm:text-5xl md:text-5xl lg:text-5xl font-semibold leading-none whitespace-nowrap">
              our <FlipWords words={words} />
            </span>
          </h1>
        </div>
        <div className="absolute inset-0 flex items-center justify-center w-full">
          <img
            src={styleVideo}
            alt="Description of the image"
            className="h-auto object-cover w-full xs:w-full sm:w-full md:w-full lg:w-full xl:w-8/12"
            style={{ zIndex: 0 }}
          />
        </div>
      </section>
    </div>



  );
}

export default Hero;
