import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import HeroAboutUs from '../components/HeroAboutUs';
import VisionAboutUs from '../components/VisionAboutUs';
import MissionAboutUs from '../components/MissionAboutUs';
import EthicsAboutUs from '../components/EthicsAboutUs';
import LampDemo from '../components/LampDemo';
import FAQAboutUs from '../components/FAQAboutUs';
import Form from '../components/Form';
import HorizontalTimeline from '../components/HorizontalTimeline';
import * as Sentry from '@sentry/react';

const App = () => {
  return (
    <main className="bg-black">      
      <Navbar />
      <LampDemo />
      <HeroAboutUs />
      <HorizontalTimeline />
      <VisionAboutUs />
      <MissionAboutUs />
      <EthicsAboutUs />
      <FAQAboutUs />
      <Form />
      <Footer />
    </main>
  )
}

export default Sentry.withProfiler(App);
