"use client";
import React from "react";
import { motion } from "framer-motion";
import { LampContainer } from "./ui/lamp";

function LampDemo() {
  return (
    <LampContainer>
      <motion.h1
        initial={{ opacity: 0.5, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.3,
          duration: 0.8,
          ease: "easeInOut",
        }}
        className="mt-0 bg-gradient-to-br from-lime-300 to-neutral-700 -py-5 bg-clip-text text-center font-medium tracking-tight text-transparent 
        text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl"
      >
        <span
          className="text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-7xl 2xl:text-8xl"
          style={{ textShadow: '1px 1px 2px rgba(255, 255, 255, 0.4)' }}
        >
          Learn More About
        </span>
        <br />
        <span
          className="text-6xl sm:text-7xl md:text-7xl lg:text-7xl xl:text-8xl 2xl:text-9xl"
          style={{ textShadow: '1px 1px 2px rgba(255, 255, 255, 0.2)' }}
        >
          Who We Are
        </span>
      </motion.h1>
    </LampContainer>
  );
}

export default LampDemo;
