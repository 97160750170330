import xnoryImg_50_Img from "/assets/images/xnory_50.png";
import ethics_img from "/assets/images/14602281_5480336.svg";
import styleVideo_video from "/assets/videos/26gsuUjoEBmLrNBxC.webp";
import mobile_app_video from "/assets/videos/mobileApp.mp4";
import web_application_img from "/assets/images/af7621d462cb64b78674ac3e9bcf8547.jpg";
import desktop_application_img from "/assets/images/image_processing20230116-28634-izznsb.png";
import embeddedSystem_img from "/assets/images/original-fca7ee068b0736feb65d0a51ff28e21e.jpg";
import iot_img from "/assets/images/original-1527b1ab3eaa93bf90412f5414c7c242.png";
import clous_services_img from "/assets/images/image_processing20220213-4100-19x6897.jpeg";
import consulting_img from "/assets/images/2150970215.jpg";
import mobile_app_img from "/assets/images/9307839_4145241.jpg";
import com_img_1 from "/assets/images/2946.jpg";
import com_img_2 from "/assets/images/2149341102.jpg";
import com_img_3 from "/assets/images/16738.jpg";
import com_img_4 from "/assets/images/2149271719.jpg";
import intel_img from "/assets/images/Intel.svg";
import ibm_img from "/assets/images/ibm.svg";
import uber_img from "/assets/images/uber.svg";
import salesforce_img from "/assets/images/salesforce.svg";
import airbnb_img from "/assets/images/airbnb.svg";
import apple_2_img from "/assets/images/apple_2.svg";
import cloud_img2 from "/assets/images/1363.jpg";
import it_consulting from "/assets/images/5324.jpg";
import enterprise_img from "/assets/images/6333.jpg";
import mobile_img from "/assets/images/90378.jpg";
import cloud_img from "/assets/images/72825.jpg";
import iotApple_img from "/assets/images/2150639966.jpg";
import devops_img from "/assets/images/43387.jpg";
import ecom_img from "/assets/images/2148482597.jpg";
import uiux_img from "/assets/images/2149749874.jpg";
import blockC_img from "/assets/images/2149102084.jpg";
import ais_img from "/assets/images/91214.jpg";
import cyberS_img from "/assets/images/2148165944.jpg";
import desk_img from "/assets/images/desk.png";
import webS_img from "/assets/images/webS.png";
import mobS_img from "/assets/images/mobS.png";
import embS_img from "/assets/images/embS.png";
import iotS_img from "/assets/images/iotS.png";
import cloS_img from "/assets/images/cloS.png";
import conS_img from "/assets/images/conS.png";
import tecS_img from "/assets/images/tecS.png";
import cybS_img from "/assets/images/cybS.png";
import datS_img from "/assets/images/datS.png";
import susS_img from "/assets/images/susS.png";
import inoS_img from "/assets/images/inoS.png";
import FinI_img from "/assets/images/2147626403.jpg";
import telI_img from "/assets/images/42735.jpg";
import agrI_img from "/assets/images/955.jpg";
import autI_img from "/assets/images/778.jpg";
import retI_img from "/assets/images/2410.jpg";
import eduI_img from "/assets/images/1282.jpg";
import medI_img from "/assets/images/41682.jpg";
import tecI_img from "/assets/images/105391.jpg";
import heaI_img from "/assets/images/84816.jpg";
import logI_img from "/assets/images/3978.jpg";
import insI_img from "/assets/images/11088.jpg";
import enrI_img from "/assets/images/4806.jpg";
import agrB_img from "/assets/images/5877.jpg";
import retB_img from "/assets/images/3409.jpg";
import logB_img from "/assets/images/1380.jpg";
import helB_img from "/assets/images/14933.jpg";
import aitB_img from "/assets/images/90876.jpg";
import finB_img from "/assets/images/13395.jpg";
import linkedin_logo from "/assets/images/linkedin.svg";
import facebook_logo from "/assets/images/facebook.svg";
import x_logo from "/assets/images/x.svg";

export const linkedin = linkedin_logo;
export const facebook = facebook_logo;
export const x = x_logo;
export const agrB = agrB_img;
export const retB = retB_img;
export const logB = logB_img;
export const helB = helB_img;
export const aitB = aitB_img;
export const finB = finB_img;
export const enrI = enrI_img;
export const insI = insI_img;
export const logI = logI_img;
export const heaI = heaI_img;
export const tecI = tecI_img;
export const medI = medI_img;
export const eduI = eduI_img;
export const retI = retI_img;
export const autI = autI_img;
export const agrI = agrI_img;
export const FinI = FinI_img;
export const telI = telI_img;
export const conS = conS_img;
export const tecS = tecS_img;
export const cybS = cybS_img;
export const datS = datS_img;
export const susS = susS_img;
export const inoS = inoS_img;
export const iotS = iotS_img;
export const cloS = cloS_img;
export const embS = embS_img;
export const mobS = mobS_img;
export const webS = webS_img;
export const deskS = desk_img;
export const cyberS = cyberS_img;
export const ais = ais_img;
export const blockC = blockC_img;
export const uiux = uiux_img;
export const ecom = ecom_img;
export const devops = devops_img;
export const iotApple = iotApple_img;
export const cloud2 = cloud_img;
export const mobile = mobile_img;
export const enterprise = enterprise_img;
export const consulting = it_consulting;
export const cloud = cloud_img2;
export const intel = intel_img;
export const ibm = ibm_img;
export const uber = uber_img;
export const salesforce = salesforce_img;
export const airbnb = airbnb_img;
export const apple_2= apple_2_img;
export const xnoryImg_50 = xnoryImg_50_Img;
export const ethics = ethics_img;
export const styleVideo = styleVideo_video;
export const mobileAppVideo = mobile_app_video;
export const webImage = web_application_img;
export const desktopImage = desktop_application_img;
export const embeddedImage = embeddedSystem_img;
export const iotImage = iot_img;
export const cloudImage = clous_services_img;
export const consultingImage = consulting_img;
export const mobileAppImage = mobile_app_img;
export const comImg1 = com_img_1;
export const comImg2 = com_img_2;
export const comImg3 = com_img_3;
export const comImg4 = com_img_4