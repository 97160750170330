"use client";
import React from "react";
import ShootingStars from "./ui/shooting-stars";
import { StarsBackground } from "./ui/stars-background";

function ShootingStarsAndStarsBackgroundDemo() {
  return (
    <div className="h-96 bg-black flex flex-col items-center justify-center relative w-full">
      <h2 className="relative z-10 text-3xl md:text-5xl md:leading-tight max-w-7xl mx-auto text-center tracking-tight font-medium bg-clip-text text-transparent bg-gradient-to-b from-neutral-500 via-white to-white flex flex-col items-center">
        <span className="text-7xl md:text-8xl p-4 font-mono font-bold">Innovate with Insights</span>
        <span className="text-lg md:text-xl mt-2 text-neutral-600 font-mono">
          Discover the latest in custom software development. Stay informed with our blog
        </span>
      </h2>
      <ShootingStars />
      <StarsBackground />
      {/* White lines on top and bottom */}
      <div
        style={{ height: "1px" }}
        className="absolute top-0 left-0 w-full bg-neutral-600"
      ></div>
      <div
        style={{ height: "1px" }}
        className="absolute bottom-0 left-0 w-full bg-neutral-600"
      ></div>
    </div>
  );
}

export default ShootingStarsAndStarsBackgroundDemo;
