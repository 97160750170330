import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';

const Model = () => {
  const projectsRef = useRef(null);
  const engineersRef = useRef(null);
  const experienceRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const animateCount = (ref, endValue) => {
      gsap.fromTo(
        ref.current,
        { textContent: 0 },
        {
          textContent: endValue,
          duration: 2,
          ease: 'power1.inOut',
          snap: { textContent: 1 },
          stagger: 0.2,
          onUpdate: function () {
            ref.current.textContent = Math.round(ref.current.textContent) + '+';
          }
        }
      );
    };

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Trigger when 50% of the component is in view
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        // Start animations when component is in view
        animateCount(projectsRef, 35);
        animateCount(engineersRef, 10);
        animateCount(experienceRef, 4);
        // Disconnect observer after triggering once
        observer.disconnect();
      }
    }, options);

    // Start observing the component
    if (projectsRef.current) {
      observer.observe(projectsRef.current);
    }

    return () => {
      // Cleanup: disconnect the observer when component unmounts
      observer.disconnect();
    };
  }, []);

  return (
    <section className="common-padding">
      <div className="screen-max-width">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-center">
          <div className="flex flex-col items-center mb-10 xs:mb-10 sm:mb-10 md:mb-0 lg:mb-0">
            <h2 ref={projectsRef} className={`text-5xl font-bold text-blue ${isVisible ? 'animate' : ''}`}>0+</h2>
            <p className="text-xl mt-2 text-white">Completed Projects</p>
          </div>
          <div className="flex flex-col items-center mb-10 xs:mb-10 sm:mb-10 md:mb-0 lg:mb-0">
            <h2 ref={engineersRef} className={`text-5xl font-bold text-green-500 ${isVisible ? 'animate' : ''}`}>0+</h2>
            <p className="text-xl mt-2 text-white">Engineers</p>
          </div>
          <div className="flex flex-col items-center mb-0 xs:mb-0 sm:mb-0 md:mb-0 lg:mb-0">
            <h2 ref={experienceRef} className={`text-5xl font-bold text-purple-500 ${isVisible ? 'animate' : ''}`}>0+</h2>
            <p className="text-xl mt-2 text-white">Years of Experience</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Model;
