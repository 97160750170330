import React from "react";
import { Carousel, Card } from "./ui/apple-cards-carousel";
import { cyberS, ais, enterprise, uiux, mobile, blockC, iotApple, cloud2, devops, ecom } from '../utils';

const AppleCardsCarouselDemo = () => {
  const cards = data.map((card, index) => (
    <Card key={card.src} card={card} index={index} />
  ));

  return (
    <div className="w-full h-full py-20 bg-grid-white/[0.2] flex flex-col items-center justify-center overflow-hidden">
      <h2 className="max-w-7xl pl-4 mx-auto text-xl sm:text-5xl md:text-7xl lg:text-7xl font-bold text-neutral-50 font-sans">
        Small Frame,
        <h1 className="text-5xl sm:text-7xl md:text-9xl lg:text-9xl">
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue via-teal-500 to-green-500">
            Big
          </span> Results</h1>
      </h2>
      <div className="w-full max-w-full overflow-hidden">
        <Carousel items={cards} />
      </div>
    </div>
  );
};

// comImg1

const data = [
  {
    category: "AI Solutions",
    title: "Revolutionize Your Business with AI.",
    src: ais,
  },
  {
    category: "Enterprise Software",
    title: "Streamline Operations with Custom Solutions.",
    src: enterprise,
  },
  {
    category: "Mobile Development",
    title: "Innovative Mobile Apps for Every Need.",
    src: mobile,
  },
  {
    category: "Cloud Services",
    title: "Scalable Cloud Solutions for Modern Enterprises.",
    src: cloud2,
  },
  {
    category: "IoT Development",
    title: "Connect Your World with IoT Innovations.",
    src: iotApple,
  },
  {
    category: "Cybersecurity",
    title: "Protect Your Data with Advanced Security.",
    src: cyberS,
  },  
  {
    category: "Blockchain",
    title: "Transform Transactions with Blockchain Technology.",
    src: blockC,
  },
  {
    category: "UI/UX Design",
    title: "Create Engaging User Experiences.",
    src: uiux,
  },
  {
    category: "E-commerce Solutions",
    title: "Boost Sales with Custom E-commerce Platforms.",
    src: ecom,
  },
  {
    category: "DevOps",
    title: "Accelerate Development with DevOps Practices.",
    src: devops,
  },
];


export default AppleCardsCarouselDemo;
