import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Label } from './ui/label';
import { Select } from './ui/select';
import { Textarea } from './ui/textarea';
import { Input } from './ui/input';
import { cn } from '../utils/cn';

const Form = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccess(false);

        const serviceID = 'service_nz8nzz6';
        const templateID = 'template_1gbcteq';
        const userID = 'lVPaep3LyTG_Gmea3';

        emailjs.sendForm(serviceID, templateID, e.target, userID)
            .then((result) => {
                setSuccess(true);
                setLoading(false);
                setTimeout(() => {
                    setSuccess(false);
                    e.target.reset(); // Reset the form fields
                }, 2000);
            }, (error) => {
                setLoading(false);
            });
    };

    const BottomGradient = () => (
        <>
            <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
            <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
        </>
    );

    const LabelInputContainer = ({ children, className }) => (
        <div className={cn("flex flex-col space-y-2 w-full", className)}>
            {children}
        </div>
    );

    return (
        <section className="h-full common-padding bg-zinc relative overflow-hidden" id="contact-form">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Left side (big text section) */}
                <div className="flex flex-col justify-center items-center md:items-end p-4">
                    <span className="w-full text-center md:text-right bg-gradient-to-r text-3xl sm:text-5xl md:text-7xl lg:text-8xl xl:text-9xl from-blue via-yellow-300 to-purple-500 text-transparent bg-clip-text">
                        Shape your tomorrow with the decisions you make today.
                    </span>
                </div>
                {/* Right side (form section) */}
                <div className="flex justify-center items-center p-4">
                    <div className="w-full max-w-md rounded-xl md:rounded-2xl p-4 md:p-8 shadow-input bg-black">
                        <div className="flex flex-col justify-center items-center">
                            <h1 className="font-bold text-3xl text-neutral-200 text-center">
                                Let's Connect
                            </h1>
                            <div className="bg-gradient-to-r from-transparent via-neutral-700 to-transparent my-2 h-[1px] w-full" />
                            <p className="text-sm max-w-sm mt-5 text-neutral-500 text-center">
                                Please fill out the form below and we'll get back to you shortly.
                            </p>
                        </div>
                        <form className="my-10" onSubmit={handleSubmit}>
                            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4">
                                <LabelInputContainer>
                                    <Label htmlFor="firstname">First name</Label>
                                    <Input id="firstname" name="from_name" placeholder="Tyler" type="text" required />
                                </LabelInputContainer>
                                <LabelInputContainer>
                                    <Label htmlFor="lastname">Last name</Label>
                                    <Input id="lastname" name="last_name" placeholder="Durden" type="text" required />
                                </LabelInputContainer>
                            </div>
                            <LabelInputContainer className="mb-4">
                                <Label htmlFor="email">Email Address</Label>
                                <Input
                                    id="email"
                                    name="email"
                                    placeholder="projectmayhem@fc.com"
                                    type="email"
                                    required
                                />
                            </LabelInputContainer>
                            <LabelInputContainer className="mb-4">
                                <Label htmlFor="category">Project Category</Label>
                                <Select id="category" name="category" required>
                                    <option value="" disabled>Select category</option>
                                    <optgroup label="Development">
                                        <option value="Desktop Application">Desktop Application</option>
                                        <option value="Web Application">Web Application</option>
                                        <option value="Mobile Application">Mobile Application</option>
                                        <option value="Embedded Systems">Embedded Systems</option>
                                        <option value="IoT Solutions">IoT Solutions</option>
                                        <option value="Cloud Services">Cloud Services</option>
                                    </optgroup>
                                    <optgroup label="Consulting Services">
                                        <option value="IT Consulting">IT Consulting</option>
                                        <option value="Technical Feasibility Study">Technical Feasibility Study</option>
                                        <option value="Cyber Security">Cyber Security</option>
                                        <option value="Data Strategy Consulting">Data Strategy Consulting</option>
                                        <option value="Sustainability Consulting">Sustainability Consulting</option>
                                        <option value="Innovation Workshops">Innovation Workshops</option>
                                    </optgroup>
                                </Select>
                            </LabelInputContainer>
                            <LabelInputContainer className="mb-4">
                                <Label htmlFor="description">Project Description</Label>
                                <Textarea id="description" name="description" placeholder="Describe your project in detail" required />
                            </LabelInputContainer>
                            <LabelInputContainer className="mb-4">
                                <Label htmlFor="priceRange">Price Range</Label>
                                <Select id="priceRange" name="priceRange" required>
                                    <option value="" disabled>Select price range</option>
                                    <option value="below $500">Below $500</option>
                                    <option value="$500-$1,000">$500 - $1,000</option>
                                    <option value="$1,000-$2,500">$1,000 - $2,500</option>
                                    <option value="$2,500-$5,000">$2,500 - $5,000</option>
                                    <option value="$5,000-$10,000">$5,000 - $10,000</option>
                                    <option value="over $10,000">Over $10,000</option>
                                </Select>
                            </LabelInputContainer>
                            <LabelInputContainer className="mb-7">
                                <Label htmlFor="duration">Project Duration</Label>
                                <Select id="duration" name="duration" required>
                                    <option value="" disabled>Select duration</option>
                                    <option value="less than 1 month">Less than 1 month</option>
                                    <option value="1-3 months">1-3 months</option>
                                    <option value="3-6 months">3-6 months</option>
                                    <option value="6-12 months">6-12 months</option>
                                    <option value="more than 12 months">More than 12 months</option>
                                </Select>
                            </LabelInputContainer>
                            <button
                                className="relative group/btn flex bg-zinc-800 border border-gray-800 w-full text-white rounded-md h-10 font-medium items-center justify-center"
                                type="submit"
                                disabled={loading}
                            >
                                {success ? (
                                    <div className="flex items-center justify-center">
                                        <svg className="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                        <span className="ml-2">Success!</span>
                                    </div>
                                ) : loading ? (
                                    <div className="w-5 h-5 border-4 border-t-transparent border-white border-solid rounded-full animate-spin" />
                                ) : (
                                    <>Contact Us &rarr;</>
                                )}
                                <BottomGradient />
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Form;
