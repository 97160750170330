import { useState, useEffect, useRef } from 'react';
import { xnoryImg_50 } from '../utils';
import { navLists } from '../constants';
import { HoverBorderGradient } from './ui/hover-border-gradient';

const Navbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // State for popup
  const dropdownRef = useRef(null); // Ref for dropdown element

  useEffect(() => {
    // Function to close dropdown when window is resized
    const handleResize = () => {
      if (showDropdown) {
        setShowDropdown(false);
      }
    };

    // Adding event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleaning up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [showDropdown]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown); // Toggle dropdown state
  };

  const closeDropdown = () => {
    setShowDropdown(false); // Function to explicitly close dropdown
  };

  const scrollToContactForm = () => {
    const contactFormSection = document.getElementById('contact-form');
    if (contactFormSection) {
      // Add smooth-scroll class to html and body temporarily
      document.documentElement.classList.add('smooth-scroll');
      document.body.classList.add('smooth-scroll');

      // Scroll to the contact form section
      contactFormSection.scrollIntoView({ behavior: 'smooth', block: 'center' });

      // Remove smooth-scroll class after scrolling
      setTimeout(() => {
        document.documentElement.classList.remove('smooth-scroll');
        document.body.classList.remove('smooth-scroll');
      }, 2500); // Adjust timing as needed

      setShowDropdown(false); // Close dropdown after scrolling
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Function to get current URL
  const getCurrentUrl = () => {
    if (typeof window !== 'undefined') {
      return window.location.pathname;
    }
    return '';
  };

  // Get current URL for active link comparison
  const currentUrl = getCurrentUrl();

  // Determine if the current page is the blog page
  const isBlogPage = currentUrl.includes('/blog'); // Adjust this condition based on your URL structure

  return (
    <>
      <header className="relative bg-transparent w-full py-5 sm:px-10 px-5 flex justify-between items-center">
        <nav className="flex w-full screen-max-width" style={{ zIndex: '999' }}>
          <div className="py-1 flex items-center gap-7 max-sm:justify-start max-sm:flex-1">
            <img src={xnoryImg_50} alt="XnorY" width={50} height={50} />
          </div>

          <div className="py-5 flex flex-1 justify-center max-sm:hidden">
            {navLists.map((nav) => (
              <a
                key={nav.name}
                href={nav.url}
                className={`nav-item px-5 text-sm cursor-pointer ${
                  currentUrl === nav.url ? 'text-white active' : 'text-gray hover:text-white'
                }`}
              >
                {nav.name}
              </a>
            ))}
          </div>

          <div className="py-3 flex items-center gap-4 max-sm:hidden">
            <button
              className="nav-item px-4 py-2 text-gray hover:text-white transition-all"
              onClick={() => setShowPopup(true)} // Show popup on click
            >
              Info
            </button>
            {!isBlogPage && ( // Conditionally render Contact Us button
              <HoverBorderGradient
                containerClassName="rounded-full"
                as="button"
                className="dark:bg-black bg-white text-black dark:text-white flex items-center space-x-2"
                onClick={scrollToContactForm} // Scroll to contact form section
              >
                <span>Contact Us</span>
              </HoverBorderGradient>
            )}
          </div>

          <div className="hidden max-sm:flex flex-1 justify-center max-sm:justify-end max-sm:mr-1">
            <button
              className="text-neutral-400 hover:text-white transition-all"
              onClick={toggleDropdown}
            >
              {!showDropdown ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-9 w-9 cursor-pointer"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 6a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zM5 10a1 1 0 00-1 1v2a1 1 0 001 1h8a1 1 0 100-2H6a1 1 0 01-1-1v-2a1 1 0 011-1z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>

          {showDropdown && (
            <div ref={dropdownRef} className="absolute p-2 top-14 left-1 right-1 bg-white bg-opacity-90 rounded-lg shadow-lg z-10 py-2">
              {navLists.map((nav) => (
                <a
                  key={nav.name}
                  href={nav.url} // Add href attribute for each dropdown item
                  className={`px-4 py-2 text-sm text-black rounded-lg font-semibold block ${
                    currentUrl === nav.url ? 'underline' : 'hover:bg-black hover:text-white'
                  }`}
                  onClick={() => {
                    // Close dropdown after navigating
                    setShowDropdown(false);
                  }}
                >
                  {nav.name}
                </a>
              ))}
              <a
                href="#"
                className={`px-4 py-2 text-sm text-black rounded-lg font-semibold block ${
                  currentUrl === '#' ? 'underline' : 'hover:bg-black hover:text-white'
                }`}
                onClick={(e) => {
                  e.preventDefault(); // Prevent default link behavior
                  setShowPopup(true); // Show popup on click
                }}
              >
                Info
              </a>
              {!isBlogPage && (
                <a
                  href="#"
                  className="px-4 py-2 text-sm text-black rounded-lg font-semibold block hover:bg-black hover:text-white"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default link behavior
                    scrollToContactForm(); // Scroll to contact form section
                  }}
                >
                  Contact Us
                </a>
              )}
            </div>
          )}

          {/* Back to Top Button */}
          <button
            className="fixed bottom-4 right-4 bg-neutral-300 text-black rounded-full p-2 shadow-lg hover:bg-blue hover:text-white transition-all"
            onClick={scrollToTop}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 10l7-7m0 0l7 7m-7-7v18" />
            </svg>
          </button>
        </nav>
      </header>

      {/* Popup Component */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" style={{ zIndex: 9999 }} onClick={() => setShowPopup(false)}>
          <div className="bg-white p-6 rounded-xl shadow-lg w-1/3 max-w-lg min-w-[350px] min-h-[200px]" onClick={(e) => e.stopPropagation()}>
            <h2 className="text-3xl mb-6 text-neutral-800 text-center">Reach Out to Us</h2>
            <table className="w-full text-left table-fixed mb-2">
              <tbody>
                <tr>
                  <td className="nav-item font-semibold align-top w-1/3">Address</td>
                  <td className="nav-item align-top">
                    <a
                      href="https://www.google.com/maps?q=7.0308263817928225,80.13074155640058"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      31, Namal Uyana, Kirindiwela, 11730
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="nav-item font-semibold align-top w-1/3">Phone</td>
                  <td className="nav-item align-top">
                    <a href="tel:+94767558232" className="text-blue-500 hover:underline">
                      (+94) 76 755 8232
                    </a>
                  </td>
                </tr>
                {/* <tr>
                  <td className="nav-item font-semibold align-top w-1/3">General</td>
                  <td className="nav-item align-top">
                    <a href="mailto:info@xnory.com" className="text-blue-500 hover:underline">
                      info@xnory.com
                    </a>
                  </td>
                </tr> */}
                <tr>
                  <td className="nav-item font-semibold align-top w-1/3">Contact</td>
                  <td className="nav-item align-top">
                    <a href="mailto:contact@xnory.com" className="text-blue-500 hover:underline">
                      contact@xnory.com
                    </a>
                  </td>
                </tr>
                {/* <tr>
                  <td className="nav-item font-semibold align-top w-1/3">Careers</td>
                  <td className="nav-item align-top">
                    <a href="mailto:careers@xnory.com" className="text-blue-500 hover:underline">
                      careers@xnory.com
                    </a>
                  </td>
                </tr> */}
                {/* <tr>
                  <td className="nav-item font-semibold align-top w-1/3">Partnerships</td>
                  <td className="nav-item align-top">
                    <a href="mailto:partnerships@xnory.com" className="text-blue-500 hover:underline">
                      partnerships@xnory.com
                    </a>
                  </td>
                </tr> */}
              </tbody>
            </table>
            <button
              className="mt-4 bg-black text-white w-full px-4 py-2 rounded-full hover:bg-neutral-800 transition-all"
              onClick={() => setShowPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <style jsx>{`
        .nav-item {
          position: relative;
          padding-bottom: 4px; /* Space between text and underline */
        }

        .nav-item.active {
          text-decoration: underline; /* Static underline for active link */
        }

        .nav-item::after {
          content: '';
          position: absolute;
          width: 0;
          height: 2px;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          background-color: transparent; /* No underline by default */
          transition: width 0.3s ease;
        }

        .nav-item:not(.active)::after {
          background-color: white;
        }

        .nav-item:hover::after {
          width: 50%; /* Full width underline on hover */
        }

        .smooth-scroll {
          scroll-behavior: smooth;
        }
      `}</style>
    </>
  );
};

export default Navbar;
