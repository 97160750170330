import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ShootingStarsAndStarsBackgroundDemo from '../components/ShootingStarsAndStarsBackgroundDemo';
// import FollowingPointerDemo from '../components/FollowingPointerDemo';
import BlogCardsContainer from '../components/BlogCardsContainer';

import * as Sentry from '@sentry/react';

const App = () => {
  return (
    <main className="bg-black">
      <Navbar />
      <ShootingStarsAndStarsBackgroundDemo />
      {/* <FollowingPointerDemo/> */}
      <BlogCardsContainer/>
      <Footer />
    </main>
  )
}

export default Sentry.withProfiler(App);
