import Navbar from '../components/Navbar';
import HeroServices from '../components/HeroServices';
import QuoteComponent from '../components/QuoteComponent';
import TextGrid_Development from '../components/TextGrid_Development';
import TextGrid_Consulting from '../components/TextGrid_Consulting';
import IndustriesSection from '../components/IndustriesSection';
import Form from '../components/Form';
import Footer from '../components/Footer';

import * as Sentry from '@sentry/react';

const App = () => {
  return (
    <main className="bg-black">
      <Navbar />
      <HeroServices/>
      <TextGrid_Development/>
      <QuoteComponent/>
      <TextGrid_Consulting/>
      <IndustriesSection/>
      <Form />
      <Footer />
    </main>
  )
}

export default Sentry.withProfiler(App);
