import React from 'react';
import { comImg4 } from '../utils';

const HeroServices = () => {
    return (
        <section className="relative w-screen h-96 overflow-hidden left-0">
            <img
                src={comImg4}
                alt="Hero-Background"
                className="absolute inset-0 w-full h-full object-cover"
            />
            <div className="absolute inset-0 w-full h-full bg-black bg-opacity-65"></div>
            <div className="relative z-10 flex flex-col items-center justify-center w-full h-full">
                <h1 className="text-4xl sm:text-5xl md:text-7xl font-bold text-white text-center">
                    Tailor-Made Software Services
                </h1>
                <p className="mt-4 text-lg sm:text-xl md:text-2xl text-white text-center">
                    Delivering tailored software solutions to meet your unique business needs
                </p>
            </div>
            {/* White lines on top and bottom */}
            <div style={{ height: '1px' }} className="absolute top-0 left-0 w-full bg-neutral-400"></div>
            <div style={{ height: '1px' }} className="absolute bottom-0 left-0 w-full bg-neutral-400"></div>
        </section>

    );
};

export default HeroServices;
