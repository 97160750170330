// components/PrivacyPolicy.jsx

import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="p-5 bg-white text-black rounded-lg shadow-md max-w-4xl mx-auto my-10">
      <h1 className="text-4xl font-bold mb-6 text-center">Privacy Policy</h1>
      <p className="mb-4 text-end">Effective Date: January 1, 2024</p>

      <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
      <p className="mb-4 ml-5">
        Welcome to XnorY. We value your privacy and are committed to protecting your personal information. This policy explains how we collect, use, and share your data.
      </p>

      <h2 className="text-2xl font-semibold mb-4">2. Information We Collect</h2>
      <p className="mb-4 ml-5">
        We may collect:
      </p>
      <ul className="list-disc list-inside mb-4 ml-6">
        <li><strong>Personal Information:</strong> Contact details such as name and email.</li>
        <li><strong>Usage Data:</strong> Data on how you use our website.</li>
        <li><strong>Cookies:</strong> Small files used to enhance your experience.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">3. How We Use Your Information</h2>
      <p className="mb-4 ml-5">
        We use your information to:
      </p>
      <ul className="list-disc list-inside mb-4 ml-6">
        <li>Provide and improve our services</li>
        <li>Communicate with you</li>
        <li>Send marketing materials (with your consent)</li>
        <li>Comply with legal requirements</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">4. Sharing Your Information</h2>
      <p className="mb-4 ml-5">
        We do not share your information with service providers. We may share your information with:
      </p>
      <ul className="list-disc list-inside mb-4 ml-6">
        <li><strong>Business Transfers:</strong> In case of company changes such as mergers or acquisitions.</li>
        <li><strong>Legal Requirements:</strong> When required by law.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">5. Data Security</h2>
      <p className="mb-4 ml-5">
        We use security measures to protect your data but cannot guarantee complete security.
      </p>

      <h2 className="text-2xl font-semibold mb-4">6. Your Rights</h2>
      <p className="mb-4 ml-5">
        You can:
      </p>
      <ul className="list-disc list-inside mb-4 ml-6">
        <li>Request deletion of your data</li>
        <li>Withdraw consent if applicable</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">7. Cookies</h2>
      <p className="mb-4 ml-5">
        We use cookies to improve your experience. You can manage cookies through your browser settings.
      </p>

      <h2 className="text-2xl font-semibold mb-4">8. Third-Party Links</h2>
      <p className="mb-4 ml-5">
        We are not responsible for third-party sites linked from our website. Review their privacy policies.
      </p>

      <h2 className="text-2xl font-semibold mb-4">9. Changes to This Policy</h2>
      <p className="mb-4 ml-5">
        We may update this policy and will notify you by posting the changes on our website.
      </p>

      <h2 className="text-2xl font-semibold mb-4">10. Contact Us</h2>
      <p className="mb-4 ml-5">
        For any further questions, please do not hesitate to contact us.
      </p>
      <address className="not-italic mb-4 justify-end text-end mt-20">
        <strong>XnorY</strong><br />
        Email: <a href="mailto:contact@xnory.com" className="text-blue-500 hover:underline">contact@xnory.com</a><br />
        Address: No.31, Namal Uyana, Maddegama, Kirindiwela
      </address>
    </div>
  );
};

export default PrivacyPolicy;
