import React from "react";
import { intel, ibm, apple_2, uber, salesforce, airbnb } from '../utils';

const Partners = () => {
    // Sample partner logos (replace with your actual images or data)
    const partnerLogos = [
        { id: 1, name: 'Intel', imageUrl: intel },
        { id: 2, name: 'IBM', imageUrl: ibm },
        { id: 3, name: 'Apple', imageUrl: apple_2 },
        { id: 4, name: 'Uber', imageUrl: uber },
        { id: 5, name: 'Salesforce', imageUrl: salesforce },
        { id: 6, name: 'Airbnb', imageUrl: airbnb },
    ];

    return (
        <section className="partners-section common-padding -mt-10">
            <div className="screen-max-width">
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-4 justify-items-center">
                    {partnerLogos.map(partner => (
                        <div key={partner.id} className="relative mb-10" title={partner.name} >
                            <img src={partner.imageUrl} alt={partner.name} className="h-8 sm:h-10 md:h-9 lg:h-10 xl:h-10 object-contain" />
                            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Partners;
