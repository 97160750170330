import React from 'react';
import { Link } from 'react-router-dom';

// Define the TextGrid component for Consulting Services
const TextGrid_Consulting = () => {
  // Sample data for each cell in the grid
  const services = [
    { id: 'consulting-services', title: "Consulting Services", description: "Get expert guidance to navigate the complexities of technology and drive your business forward with our comprehensive consulting services." },
    { id: 'technical-feasibility-study', title: "Technical Feasibility Study", description: "Evaluate the technical feasibility of your project with our expert R&D team, ensuring the viability and success of your investment." },
    { id: 'cyber-security', title: "Cyber Security", description: "Protect your digital assets with our proactive cyber security services, safeguarding your business from potential threats." },
    { id: 'data-strategy-consulting', title: "Data Strategy Consulting", description: "Enable data-driven decision-making with our end-to-end data strategy consulting, combining design, implementation, and provisioning." },
    { id: 'sustainability-consulting', title: "Sustainability Consulting", description: "Advance your sustainability initiatives with our comprehensive consulting services, helping you achieve your net-zero goals." },
    { id: 'innovation-workshops', title: "Innovation Workshops", description: "Participate in our innovation workshops designed to foster creative thinking and accelerate the development of breakthrough ideas for your business." }
  ];

  return (
    <section id="consulting" className="w-screen overflow-hidden h-full common-padding bg-black">
      <div className="screen-max-width">
        <h2 className="text-center mb-16 py-3 text-3xl sm:text-4xl md:text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-400 via-blue-500 to-purple-600">
          Consulting Services
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {services.map((service, index) => (
            <div
              key={service.id}
              className="bg-black border border-neutral-500 p-6 rounded-md flex flex-col justify-between cursor-pointer text-white transition-transform transform hover:bg-neutral-800 hover:scale-105"
            >
              <div className="flex flex-col">
                <h3 className="text-xl sm:text-3xl font-bold mb-6">{service.title}</h3>
                <p className="text-sm sm:text-base mb-4 text-left">{service.description}</p>
              </div>
              <Link
                to={`/services-overview/${service.id}`}
                className="inline-block px-2 py-1 text-sm font-semibold text-neutral-100 bg-gradient-to-r from-teal-400 via-blue-500 to-purple-600 rounded-md shadow-md hover:opacity-80 transition-opacity self-end"
              >
                Learn more
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TextGrid_Consulting;
