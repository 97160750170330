"use client";
import React, { useRef, useState } from 'react';

const TimelineItem = ({ date, title, description }) => (
  <div className="relative flex flex-col items-center w-[250px] h-[300px] bg-transparent rounded-lg shadow-lg p-4">
    <div className="absolute top-3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-6 h-6 bg-blue rounded-full border-2 border-white"></div>
    <div className="mt-10 text-center">
      <h3 className="text-xl font-semibold text-neutral-100">{title}</h3>
      <span className="block text-sm text-neutral-300 mt-1">{date}</span>
      <p className="text-neutral-400 mt-2 text-wrap text-justify">{description}</p>
    </div>
  </div>
);

const HorizontalTimeline = () => {
  const events = [
    {
      date: 'January 2020',
      title: 'Company Founded',
      description: 'Founded in Sri Lanka, our company started with a mission to deliver innovative software solutions with a small but dedicated team of 10 developers and engineers.',
    },
    {
      date: 'March 2020',
      title: 'First Client Project',
      description: 'Completed our first client project, marking the beginning of our journey in providing custom software solutions tailored to local needs.',
    },
    {
      date: 'July 2021',
      title: 'Expanded Team',
      description: 'Grew our team to better handle increasing project demands and provide enhanced services to our growing client base.',
    },
    {
      date: 'November 2021',
      title: 'Established Key Partnerships',
      description: 'Formed strategic partnerships with local businesses to collaborate on custom software solutions and enhance service offerings.',
    },
    {
      date: 'June 2022',
      title: 'Local Recognition',
      description: 'Received recognition from local industry groups for our commitment to delivering high-quality software solutions and excellent client service.',
    },
    {
      date: 'September 2022',
      title: 'Successful Project Delivery',
      description: 'Delivered a significant project for a major local client, showcasing our ability to handle complex requirements and deliver on time.',
    },
    {
      date: 'February 2023',
      title: 'New Service Offering',
      description: 'Introduced a new service to address emerging needs in the local market, further expanding our portfolio of custom solutions.',
    },
    {
      date: 'August 2023',
      title: 'Client Growth',
      description: 'Achieved a milestone with a notable increase in our client base, reflecting our growing reputation and the quality of our services.',
    },
  ];



  const timelineRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const startDrag = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - timelineRef.current.offsetLeft);
    setScrollLeft(timelineRef.current.scrollLeft);
  };

  const stopDrag = () => {
    setIsDragging(false);
  };

  const onDrag = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - timelineRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Scroll-fast
    timelineRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <section className="w-screen overflow-hidden h-auto common-padding border-t-2 border-neutral-900">
      <div
        className="relative flex items-center space-x-8 cursor-grab active:cursor-grabbing overflow-x-scroll overflow-y-hidden"
        ref={timelineRef}
        onMouseDown={startDrag}
        onMouseLeave={stopDrag}
        onMouseUp={stopDrag}
        onMouseMove={onDrag}
        style={{
          whiteSpace: 'nowrap',
          scrollbarWidth: 'none', /* Firefox */
          msOverflowStyle: 'none' /* Internet Explorer and Edge */
        }}
      >
        <div className="absolute h-1 bg-blue top-3 left-5 right-0 transform w-[2500px]"></div>
        <div className="flex space-x-8">
          {events.map((event, index) => (
            <TimelineItem key={index} {...event} />
          ))}
        </div>
      </div>
      <style jsx>{`
        .relative::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      `}</style>
    </section>
  );
};

export default HorizontalTimeline;
