import React from "react";
import { InfiniteMovingCards } from "./ui/infinite-moving-cards";

const Stories = () => {
  const testimonials = [
    {
      quote: "RetailCo enhanced customer engagement with a mobile app featuring personalized recommendations, mobile payments, and real-time inventory updates. XnorY's solution increased customer satisfaction by 30% and mobile sales by 45%.",
      name: "Alice Johnson - Chief Executive Officer",
      title: "RetailCo",
    },
    {
      quote: "HealthWave launched a secure web app for remote patient monitoring and telemedicine. XnorY's development expanded their reach, reduced no-show rates by 25%, and improved patient outcomes.",
      name: "Jessica Davis - Director of Product Development",
      title: "HealthWave",
    },
    {
      quote: "FinTech Solutions streamlined financial analysis with a desktop app featuring advanced analytics and automated reporting. XnorY's solution increased productivity by 40% and reduced errors by 70%.",
      name: "David Smith - Chief Technology Officer",
      title: "FinTech Solutions",
    },
    {
      quote: "StyleMart boosted performance and sales with a scalable web app. XnorY's solution improved site performance by 50% and increased online sales by 60%.",
      name: "Robert Garcia - Director of Customer Support",
      title: "StyleMart",
    },
    {
      quote: "EduTech Inc. engaged students with a mobile app for interactive learning and collaboration. XnorY's app increased student engagement by 35% and improved learning outcomes.",
      name: "Linda Thompson - Director of Human Resources",
      title: "EduTech Inc.",
    },
    {
      quote: "LogiTrack optimized their logistics with a desktop app for real-time tracking and route management. XnorY's solution reduced delivery times by 20% and cut operational costs by 15%.",
      name: "Emily Brown - Chief Operating Officer",
      title: "LogiTrack",
    },
    {
      quote: "RealtyPro enhanced services with a web app offering virtual tours and secure transactions. XnorY's solution increased property inquiries by 40% and boosted sales.",
      name: "James Martinez - Director of Engineering",
      title: "RealtyPro",
    },
    {
      quote: "TravelX provided personalized travel experiences with a mobile app. XnorY's solution increased user satisfaction by 50% and repeat bookings by 30%.",
      name: "Michael Lee - Chief Financial Officer",
      title: "TravelX",
    },
    {
      quote: "FinServe modernized their services with a web app for online banking and investment tracking. XnorY's solution increased online transactions and customer retention.",
      name: "John Miller - Vice President of Sales",
      title: "FinServe",
    },
    {
      quote: "ManuTech streamlined production with a desktop app for real-time metrics and inventory management. XnorY's solution increased efficiency by 25% and reduced costs by 20%.",
      name: "Sarah Wilson - Vice President of Marketing",
      title: "ManuTech",
    },
  ];

  const gradients = [
    "linear-gradient(180deg, #FF9A8B, #FF6A88, #FF99AC)", // Light pink shades
    "linear-gradient(180deg, #2AFADF, #2A5298)", // Dark blue shades
    "linear-gradient(180deg, #FDB99B, #CF8BF3)", // Light peach and purple
    "linear-gradient(180deg, #FFDEE9, #B5FFFC)", // Light pink and blue
    "linear-gradient(180deg, #C6FFDD, #FBD786, #f7797d)", // Light green, yellow, pink
    "linear-gradient(180deg, #2AFADF, #4C83FF)", // Light teal and blue
    "linear-gradient(180deg, #3EECAC, #EE74E1)", // Light green and pink
    "linear-gradient(180deg, #FDC830, #F37335)", // Light yellow and orange
    "linear-gradient(180deg, #56CCF2, #2F80ED)", // Light blue shades
    "linear-gradient(180deg, #F0C27B, #4B1248)", // Light gold and dark purple
];



  return (
    <section className="common-padding bg-black">
      <div className="screen-max-width">
        <h1 className="md:text-6xl text-5xl font-bold text-center text-white relative z-20 mb-6">
          Success <span className="bg-gradient-to-r from-green-500 to-amber-400 text-transparent bg-clip-text">Stories</span>
        </h1>
        <div className="h-[20rem] rounded-md flex flex-col antialiased bg-black items-center justify-center relative overflow-hidden">
          <InfiniteMovingCards
            items={testimonials}
            direction="left"
            speed="slow"
            backgrounds={gradients} // Pass the gradients
          />
        </div>
      </div>
    </section>
  );
}

export default Stories;
