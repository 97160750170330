export const navLists = [
  { name: "Home", url: "/" },
  { name: "Services", url: "/services" },
  { name: "Blog", url: "/blog" },
  { name: "About Us", url: "/about-us" }
];

export const sizes = [
  { label: '6.1"', value: "small" },
  { label: '6.7"', value: "large" },
];

export const footerLinks = [
  {
    name: "Privacy Policy",
    href: "/privacy-policy",
  },
  {
    name: "Terms of Service",
    href: "/terms-of-service",
  },
  {
    name: "Our Services",
    href: "/services",
  },
  {
    name: "Blog",
    href: "/blog",
  },
];

export const socialLinks = [
  // {
  //   name: "Facebook",
  //   href: "https://www.facebook.com/yourprofile", // Replace with your Facebook profile/link
  //   icon: "facebook",
  // },
  {
    name: "X",
    href: "https://x.com/XnorYcom",
    icon: "x",
  },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/xnory",
    icon: "linkedin",
  },
  // {
  //   name: "Instagram",
  //   href: "https://www.instagram.com/yourprofile", // Replace with your Instagram profile/link
  //   icon: "instagram",
  // }
];
