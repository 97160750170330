import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home.jsx';
import Services from './pages/Services.jsx';
import Blog from './pages/Blog.jsx';
import PrivacyPolicy from './pages/PrivacyPolicy.jsx';
import AboutUs from './pages/AboutUs.jsx';
import TermsOfService from './pages/TermsOfService.jsx';
import ServiceOverview from './pages/ServicesOverview.jsx'; // Corrected import for ServiceOverview
import BlogOverview from './pages/BlogOverview.jsx'; // Corrected import for BlogOverview
import './index.css';

// Sentry setup
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "your-dsn-here", // replace with your actual DSN
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.metrics.metricsAggregatorIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// Render the application
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services-overview/:serviceId" element={<ServiceOverview />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/blog-overview/:blogId" element={<BlogOverview />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
