import React from "react";
import { cn } from "../utils/cn";
import {
  IconCurrencyDollar,
  IconFocus2,
  IconBrandSpeedtest,
  IconHelp,
  IconFiles,
  IconCube,
  IconRocket,
  IconAdjustments,
} from "@tabler/icons-react";

export function FeaturesSectionDemo() {
  const features = [
    {
      title: "Pricing like no other",
      description: "We offer competitive pricing with no hidden fees, making top-tier software accessible for all.",
      icon: <IconCurrencyDollar />,
    },
    {
      title: "Fast Development Cycles",
      description: "Deliver projects swiftly with our efficient and agile development processes.",
      icon: <IconBrandSpeedtest />,
    },
    {
      title: "Scalable Architecture",
      description: "Our systems grow with your business, ensuring seamless scalability.",
      icon: <IconCube />,
    },
    {
      title: "Cutting-Edge Technology",
      description: "We utilize the latest tech to keep you ahead of the competition.",
      icon: <IconRocket />,
    },
    {
      title: "Quality Assurance",
      description: "Rigorous testing to ensure the highest quality and reliability of your software.",
      icon: <IconAdjustments />,
    },
    {
      title: "24/7 Customer Support",
      description: "Our dedicated support team is available around the clock to assist you.",
      icon: <IconHelp />,
    },
    {
      title: "Comprehensive Documentation",
      description: "Detailed documentation to support your development and deployment processes.",
      icon: <IconFiles />,
    },
    {
      title: "Client-Centric Approach",
      description: "We prioritize your needs and work closely with you to achieve your goals.",
      icon: <IconFocus2 />,
    },
  ];

  return (

    <section className="w-screen common-padding overflow-hidden h-full bg-gradient-to-b from-black to-neutral-950">
      <div className="screen-max-width">
        <div className="px-8 mb-8">
          <h4 className="text-3xl md:text-5xl lg:leading-tight max-w-5xl mx-auto text-center tracking-tight font-medium text-white">
            Unmatched Features for Your Business Success
          </h4>
          <p className="text-sm lg:text-base text-center max-w-2xl  my-4 mx-auto font-normal text-neutral-300">
            Discover a suite of features designed to drive your business forward. From competitive pricing and fast development cycles to cutting-edge technology and 24/7 support, our custom software development services ensure you get the best solutions tailored to your needs
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 relative z-10 py-10 max-w-7xl mx-auto">
          {features.map((feature, index) => (
            <Feature key={feature.title} {...feature} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
}

const Feature = ({ title, description, icon, index }) => {
  return (
    <div
      className={cn(
        "flex flex-col lg:border-r py-10 relative group/feature dark:border-neutral-800",
        (index === 0 || index === 4) && "lg:border-l dark:border-neutral-800",
        index < 4 && "lg:border-b dark:border-neutral-800"
      )}
    >
      {index < 4 && (
        <div className="opacity-0 group-hover/feature:opacity-100 transition duration-200 absolute inset-0 h-full w-full bg-gradient-to-t from-neutral-800 to-transparent pointer-events-none" />
      )}
      {index >= 4 && (
        <div className="opacity-0 group-hover/feature:opacity-100 transition duration-200 absolute inset-0 h-full w-full bg-gradient-to-b from-neutral-800 to-transparent pointer-events-none" />
      )}
      <div className="mb-4 relative z-10 px-10 text-neutral-400">
        {icon}
      </div>
      <div className="text-lg font-bold mb-2 relative z-10 px-10">
        <div className="absolute left-0 inset-y-0 h-6 group-hover/feature:h-8 w-1 rounded-tr-full rounded-br-full bg-neutral-700 group-hover/feature:bg-blue transition-all duration-200 origin-center" />
        <span className="group-hover/feature:translate-x-2 transition duration-200 inline-block text-neutral-100">
          {title}
        </span>
      </div>
      <p className="text-sm text-neutral-300 max-w-xs relative z-10 px-10">
        {description}
      </p>
    </div>
  );
};

export default FeaturesSectionDemo;
