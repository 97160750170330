// services-overview.jsx
import { useEffect } from 'react';
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Form from '../components/Form';
import NotFound from '../components/NotFound';
import ServicesBlogView from '../components/ServicesBlogView'; // Import the new component
import * as Sentry from '@sentry/react';
import { cloS, webS, deskS, mobS, embS, iotS, conS, tecS, cybS, datS, susS, inoS } from '../utils';

const servicesData = {
    'desktop-applications': {
        title: "Desktop Applications",
        description: [
            "Our desktop application development services focus on creating robust and scalable solutions tailored to meet the unique needs of your business. We start by understanding your specific requirements and business goals, which allows us to design applications that enhance efficiency and productivity across your organization.",
            "We employ a range of technologies to build high-performance desktop applications, including .NET, Java, and C++. These technologies ensure that the applications we develop are efficient, reliable, and maintainable. By leveraging modern frameworks and libraries, we provide a seamless user experience and robust functionality that can scale with your business.",
            "To manage the development process, we follow Agile methodologies. This approach allows for iterative development and continuous feedback, ensuring that we can adapt to changing requirements and deliver a product that truly meets your needs. Regular sprints and reviews are integral to our process, ensuring that the final product aligns perfectly with your vision and expectations.",
            "Security and integration are paramount in our development process. We implement best practices for data protection and application security to safeguard your information. Additionally, our applications are designed to integrate smoothly with your existing systems, ensuring that they fit seamlessly into your IT infrastructure and deliver the highest value to your business.",
            "Our commitment to quality extends beyond development. We conduct thorough testing and quality assurance to ensure that our desktop applications perform flawlessly under various conditions. This includes functional testing, performance testing, and user acceptance testing to validate that the application meets all specified requirements and provides a positive user experience.",
            "By choosing our desktop application development services, you benefit from our extensive expertise and dedication to delivering high-quality solutions. Our goal is to help you achieve your business objectives by providing applications that not only meet your needs but also drive innovation and growth within your organization."
        ],
        image: deskS,
        textColor: "text-amber-500"
    },
    'web-applications': {
        title: "Web Applications",
        description: [
            "At our company, we leverage our extensive expertise to build responsive and user-friendly web applications tailored to meet your specific needs. We start by thoroughly understanding your business objectives and user requirements. This foundational step ensures that our web applications are not only functional but also provide a seamless and engaging user experience across all devices.",
            "Our development process incorporates a range of modern technologies to create high-quality web applications. For the front-end, we use popular frameworks and libraries such as React, Angular, and Vue.js. These technologies allow us to build interactive and dynamic user interfaces that captivate users and provide an intuitive experience. On the back-end, we utilize robust platforms like Node.js, Django, and Ruby on Rails to manage server-side logic and database interactions efficiently.",
            "We follow agile methodologies to manage the development process, which involves iterative cycles and regular feedback sessions. This approach allows us to adapt quickly to changes in requirements and ensures continuous improvement throughout the project. By breaking down the project into manageable sprints, we deliver incremental updates and maintain flexibility to meet evolving needs.",
            "Security is a top priority in our web application development process. We implement best practices to safeguard user data and protect against potential vulnerabilities. Our team conducts thorough security assessments and employs encryption, secure authentication mechanisms, and regular updates to mitigate risks and ensure the application’s resilience against threats.",
            "Performance optimization is another critical aspect of our development process. We focus on creating fast-loading applications with smooth interactions to enhance user satisfaction. Our performance tuning includes techniques such as code splitting, lazy loading, and efficient caching strategies to ensure that your web application operates at peak efficiency and delivers an exceptional user experience.",
            "Beyond development, we provide ongoing support and maintenance to ensure that your web application remains up-to-date and performs optimally. Our dedicated support team is available to address any issues, implement updates, and incorporate new features as your business evolves. This commitment to long-term success ensures that your web application continues to meet your needs and drive growth."
        ],
        image: webS,
        textColor: "text-indigo-400"
    },
    'mobile-applications': {
        title: "Mobile Applications",
        description: [
            "Our mobile application development services are dedicated to creating innovative and user-centric apps that drive business growth and deliver exceptional user experiences. We start by thoroughly understanding your target audience and business objectives, ensuring that the app aligns perfectly with both user needs and strategic goals.",
            "We specialize in developing mobile applications for both iOS and Android platforms, utilizing native development tools alongside cross-platform frameworks like React Native and Flutter. This dual approach allows us to create high-quality, performant apps that leverage the unique features of each platform while optimizing development time and cost through shared codebases.",
            "Our development process is guided by agile methodologies, promoting flexibility and iterative improvements. We collaborate closely with you through regular sprints and feedback loops, continuously refining the app's features and functionality. This iterative approach ensures that the final product not only meets but exceeds your expectations, adapting to any evolving requirements.",
            "To guarantee that our mobile applications are fast, reliable, and secure, we implement rigorous testing protocols throughout the development cycle. This includes performance testing, usability testing, and comprehensive security assessments to identify and address potential issues before the app's launch. Our commitment to quality ensures that users enjoy a smooth and stable experience across all devices.",
            "Beyond robust development practices, we focus on creating feature-rich applications that seamlessly integrate with other systems and services. Whether it's through API integrations, third-party services, or advanced functionalities like push notifications and in-app purchases, we aim to enhance the app's capabilities and provide a comprehensive solution tailored to your business needs.",
            "Our post-launch support and maintenance services ensure the continued success and relevance of your mobile applications. We offer ongoing updates, performance monitoring, and feature enhancements, ensuring that your app evolves with your business and continues to deliver value to your users."
        ],
        image: mobS,
        textColor: "text-orange-400"
    },
    'embedded-systems': {
        title: "Embedded Systems",
        description: [
            "Our company excels in designing and developing sophisticated embedded systems that seamlessly integrate with your hardware to achieve exceptional performance. We start with an in-depth analysis of your specific project requirements and operational objectives, ensuring that we tailor our solutions to meet your unique needs and deliver optimal results.",
            "Leveraging a diverse array of technologies, including advanced microcontrollers, real-time operating systems (RTOS), and low-level programming languages like C and C++, we create embedded systems that are robust, efficient, and capable of handling intricate tasks and real-time processing demands. Our expertise in these areas ensures that your system performs reliably under various conditions.",
            "We adhere to Agile methodologies in our development process, allowing us to manage projects through iterative cycles with continuous feedback. This approach helps us remain flexible and responsive to changes, ensuring that we can incorporate modifications efficiently. Regular sprints and reviews are integral to our process, keeping the development aligned with your evolving requirements and project goals.",
            "Quality and security are at the forefront of our development process. We conduct rigorous testing and validation to ensure that our embedded systems adhere to the highest standards of performance and reliability. Our comprehensive testing protocols include functional and stress testing, as well as adherence to industry standards to mitigate potential issues and enhance system robustness.",
            "Beyond development, we provide extensive support and maintenance services to ensure the ongoing success of your embedded systems. Our dedicated support team is available to address any technical issues, implement updates, and make necessary adjustments, ensuring that your system remains effective and current throughout its lifecycle.",
            "By selecting our embedded systems services, you benefit from a fusion of cutting-edge technology and expert craftsmanship. Our dedication to delivering high-quality, dependable, and efficient solutions helps you achieve your business objectives and fosters innovation in your products and services."
        ],
        image: embS,
        textColor: "text-green-400"
    },
    'iot-solutions': {
        title: "IoT Solutions",
        description: [
            "Our IoT solutions are designed to revolutionize how you connect and manage your devices, bringing the power of smart technology to your business. We begin by understanding your specific needs and goals, which allows us to tailor IoT solutions that enhance operational efficiency and drive innovation across your organization.",
            "We utilize a wide range of IoT technologies, including sensor integration, cloud-based data management, and advanced analytics platforms. By employing cutting-edge hardware and software, we create interconnected systems that facilitate real-time data collection, monitoring, and control, giving you valuable insights into your operations.",
            "Our development process follows Agile methodologies to ensure flexibility and adaptability. We work closely with you through iterative cycles, allowing us to refine and enhance the solution based on your feedback. This approach ensures that the final product aligns with your evolving requirements and delivers maximum value.",
            "Security is a top priority in our IoT solutions. We implement robust security measures, including encryption, secure authentication, and regular vulnerability assessments, to protect your data and devices from potential threats. Our commitment to security ensures that your IoT infrastructure remains safe and resilient.",
            "In addition to implementation, we offer ongoing support and maintenance to keep your IoT systems running smoothly. Our dedicated team provides regular updates, troubleshooting, and optimization services, ensuring that your solutions continue to perform at their best and adapt to any new challenges.",
            "By choosing our IoT solutions, you harness the full potential of connected devices to improve efficiency, drive innovation, and gain a competitive edge in your industry. Our expertise in IoT technology and commitment to excellence will help you achieve your business objectives and transform your operations."
        ],
        image: iotS,
        textColor: "text-sky-400"
    },
    'cloud-services': {
        title: "Cloud Services",
        description: [
            "Our cloud services are designed to optimize your operations through scalable, secure, and cost-effective solutions. We begin by assessing your business needs and current infrastructure, which allows us to develop a cloud strategy that aligns with your goals and maximizes the benefits of cloud technology.",
            "We offer a comprehensive range of cloud services, including cloud migration, management, and optimization. Whether you're looking to migrate existing systems to the cloud, manage and monitor your cloud environment, or enhance performance through optimization, our team has the expertise to deliver effective solutions tailored to your needs.",
            "Our development process is guided by Agile methodologies, enabling us to manage projects through iterative phases and continuous feedback. This approach allows for flexibility and ensures that we can adapt to changing requirements, delivering a solution that meets your business needs and drives growth.",
            "Security and compliance are critical components of our cloud services. We implement industry best practices for data protection, including encryption, access controls, and regular security assessments, to safeguard your information and ensure compliance with relevant regulations and standards.",
            "Beyond implementation, we provide ongoing support and maintenance to ensure the continued success of your cloud environment. Our team is available to address any issues, perform updates, and optimize performance, ensuring that your cloud infrastructure remains effective and aligned with your business objectives.",
            "By leveraging our cloud services, you can achieve greater flexibility, efficiency, and scalability in your operations. Our expertise and commitment to delivering high-quality cloud solutions will help you optimize your IT infrastructure and harness the full potential of the cloud to drive innovation and growth."
        ],
        image: cloS,
        textColor: "text-yellow-400"
    },
    'consulting-services': {
        title: "Consulting Services",
        description: [
            "Our Consulting Services offer expert guidance to help you navigate the complexities of technology and drive your business forward. We deliver tailored solutions designed to address your specific needs, ensuring you achieve your strategic goals with efficiency and effectiveness.",
            "We start by conducting a comprehensive analysis of your existing processes, challenges, and objectives. This thorough assessment enables us to create a customized consulting plan that is precisely aligned with your unique requirements and maximizes your potential for success.",
            "Our team of seasoned consultants employs industry best practices and cutting-edge technological advancements to provide actionable insights and strategic recommendations. We work collaboratively with you to implement these strategies, ensuring they align with your business goals and deliver tangible, measurable results.",
            "Whether you're seeking support with digital transformation, process optimization, or strategic planning, our consulting services are designed to foster growth and enhance your competitive advantage. We focus on delivering solutions that not only address immediate challenges but also pave the way for long-term success.",
            "Our consulting approach emphasizes a partnership with your organization, ensuring that our solutions are integrated seamlessly into your operations. We prioritize your needs and objectives, working closely with you to drive meaningful improvements and achieve sustainable results.",
            "By leveraging our Consulting Services, you gain access to a wealth of expertise and insights that empower you to overcome obstacles and capitalize on opportunities. Let us help you transform your business and achieve your strategic vision with confidence and clarity."
        ],
        image: conS,
        textColor: "text-amber-600"
    },
    'technical-feasibility-study': {
        title: "Technical Feasibility Study",
        description: [
            "Assess the viability of your project with our in-depth Technical Feasibility Study, designed to evaluate the technical, operational, and financial aspects of your proposed initiative. Our expert R&D team provides you with a thorough analysis to ensure that your investment is both feasible and strategic.",
            "We start by conducting a comprehensive review of your project requirements and objectives. Our team then explores available technical solutions, examining factors such as system architecture, technology stack, and resource needs to determine the best approach for your project.",
            "Our feasibility study also includes an evaluation of potential risks and challenges that could impact your project. We identify technical hurdles and provide insights into how these challenges can be addressed, helping you anticipate and mitigate potential issues before they arise.",
            "By combining our technical expertise with industry best practices, we deliver a clear and actionable feasibility report. This report equips you with the information needed to make informed decisions and lays the groundwork for successful project implementation.",
            "Our goal is to ensure that your project is not only technically viable but also positioned for success. We work closely with you to understand your goals and provide strategic recommendations that align with your objectives and budget.",
            "With our Technical Feasibility Study, you gain confidence in your project’s technical foundation and can move forward with greater certainty. Let us help you navigate the complexities of your project and achieve your vision with clarity and precision."
        ],
        image: tecS,
        textColor: "text-yellow-400"
    },
    'cyber-security': {
        title: "Cyber Security",
        description: [
            "Safeguard your digital assets with our comprehensive cyber security services, designed to protect your business from evolving threats and vulnerabilities. Our proactive approach ensures that your data and systems are secured against potential cyber risks, maintaining the integrity of your operations.",
            "Our process begins with a detailed assessment of your existing security infrastructure. We identify vulnerabilities and potential risks to your digital environment, using this information to develop a customized security strategy that addresses your unique needs and fortifies your defenses.",
            "Our cyber security services encompass threat detection and prevention, rapid incident response, and continuous monitoring. We employ cutting-edge technologies and methodologies to detect and mitigate threats in real-time, ensuring your systems remain protected. Additionally, we offer security awareness training for your staff to enhance their ability to recognize and respond to potential security breaches.",
            "With a focus on best practices and advanced security solutions, we help you build a resilient security framework that can withstand and adapt to emerging threats. Our team works tirelessly to ensure that your business maintains a robust security posture and minimizes the risk of cyber-attacks.",
            "Our commitment extends beyond initial implementation, offering ongoing support and updates to keep your security measures current and effective. We continuously monitor the threat landscape and adjust your security strategy to address new and evolving threats.",
            "Partner with us to secure your digital infrastructure and protect your business from cyber threats. Our expertise in cyber security ensures that you can focus on your core operations with confidence, knowing that your data and systems are well-defended against potential risks."
        ],
        image: cybS,
        textColor: "text-red-500"
    },
    'data-strategy-consulting': {
        title: "Data Strategy Consulting",
        description: [
            "Unlock the full potential of your data with our comprehensive data strategy consulting services. We offer end-to-end solutions that encompass design, implementation, and provisioning, empowering your organization to make informed, data-driven decisions and gain a competitive edge in the market.",
            "Our process begins with a thorough assessment of your current data landscape. We identify gaps, challenges, and opportunities to enhance your data practices. This assessment serves as the foundation for developing a tailored data strategy that aligns with your business objectives and supports your decision-making processes effectively.",
            "Our data strategy services include robust data governance frameworks, innovative data architecture design, and the implementation of advanced data management solutions. We work with you to establish best practices for data quality, security, and compliance, ensuring that your data assets are managed efficiently and responsibly.",
            "We also specialize in data analytics and reporting, helping you transform raw data into actionable insights. Our team assists with the development of sophisticated analytical models and reporting tools that provide valuable insights and support strategic decision-making.",
            "By fostering a data-driven culture within your organization, we enable you to leverage data as a strategic asset. Our expertise helps you integrate data-driven insights into your business processes, driving growth and innovation while enhancing operational efficiency.",
            "Partner with us to enhance your data strategy and management practices. Our commitment to delivering high-quality solutions ensures that you can navigate the complexities of data management and harness the power of your data to achieve your business goals and sustain long-term success."
        ],
        image: datS,
        textColor: "text-sky-500"
    },
    'sustainability-consulting': {
        title: "Sustainability Consulting",
        description: [
            "Advance your sustainability initiatives with our comprehensive consulting services, designed to help you achieve your net-zero goals and enhance your environmental, social, and governance (ESG) performance. Our sustainability consulting services are tailored to support your business in operating responsibly and sustainably, ensuring that you meet both regulatory requirements and stakeholder expectations.",
            "We start by conducting a thorough assessment of your current sustainability practices, identifying areas for improvement and opportunities for innovation. This detailed evaluation forms the basis of a customized sustainability strategy that aligns with your business objectives and industry standards. Our approach ensures that sustainability becomes an integral part of your business operations, driving efficiency and value.",
            "Our range of services includes carbon footprint analysis, sustainability reporting, and the implementation of green technologies and practices. We work closely with your team to develop actionable plans that reduce environmental impact and promote sustainable growth. Our experts are adept at identifying the most effective solutions for energy efficiency, waste reduction, and resource management.",
            "In addition to technical and strategic support, we provide guidance on stakeholder engagement and communication. Building a strong reputation for sustainability requires transparent and proactive communication with all stakeholders, including employees, customers, investors, and regulators. We help you craft compelling sustainability reports and develop communication strategies that highlight your commitment to sustainability.",
            "Our sustainability consulting services also encompass the integration of sustainable practices into your corporate culture. We offer training and workshops to educate your employees on the importance of sustainability and how they can contribute to your goals. This holistic approach ensures that sustainability is embedded at all levels of your organization, fostering a culture of continuous improvement.",
            "By leveraging our expertise in sustainability, you can reduce your environmental impact, enhance your social responsibility, and achieve long-term business success. Our commitment to delivering high-quality sustainability solutions will help you navigate the complexities of sustainability challenges and capitalize on new opportunities for growth and innovation."
        ],
        image: susS,
        textColor: "text-green-500"
    },
    'innovation-workshops': {
        title: "Innovation Workshops",
        description: [
            "Our innovation workshops are meticulously designed to foster a culture of creativity and accelerate the development of breakthrough ideas for your business. These workshops provide a collaborative and stimulating environment where your team can explore new concepts, solve complex problems, and drive innovation. By engaging in structured yet flexible activities, participants are encouraged to think outside the box and challenge conventional thinking.",
            "We begin by thoroughly understanding your business challenges and objectives, tailoring each workshop to address your specific needs. Our experienced facilitators use proven methodologies and tools to guide your team through the innovation process. This personalized approach ensures that the workshop is relevant and impactful, focusing on areas that will provide the most value to your organization.",
            "During the workshops, we employ a variety of activities designed to stimulate creative thinking and collaboration. These include brainstorming sessions, where participants generate a wide array of ideas; prototyping exercises, which bring ideas to life through tangible models; and idea validation processes, which test the feasibility and potential impact of each concept. This hands-on approach helps your team to rapidly develop and refine innovative solutions.",
            "In addition to the core activities, our innovation workshops emphasize the importance of cross-functional collaboration. By bringing together diverse perspectives from different departments and levels within your organization, we foster a holistic approach to problem-solving and innovation. This collaborative dynamic not only enhances the quality of ideas generated but also promotes a culture of shared ownership and commitment to innovation.",
            "We understand that generating great ideas is only the first step. Therefore, we provide comprehensive follow-up support to help you implement the ideas developed during the workshop. This includes strategic planning, resource allocation, and ongoing advisory services to ensure that the innovative solutions are effectively integrated into your business operations and deliver tangible results.",
            "By participating in our innovation workshops, your organization can cultivate a sustainable culture of innovation, drive continuous improvement, and maintain a competitive edge in the market. Our workshops empower your team to think creatively, act decisively, and transform innovative ideas into actionable strategies that propel your business forward."
        ],
        image: inoS,
        textColor: "text-orange-500"
    }

};

const ServicesOverview = () => {
    const { serviceId } = useParams();
    const service = servicesData[serviceId];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [serviceId]);

    if (!service) {
        return <div className="text-white text-center mt-20 text-5xl">
            <NotFound />
        </div>;
    }

    return (
        <main className="bg-black">
            <Navbar />
            <section className="w-screen overflow-hidden h-full common-padding">
                <div className="screen-max-width">
                    <nav className="text-sm text-white -mt-20 md:-mt-32 font-mono">
                        <Link to="/services" className="text-lime-400 hover:text-gray-200">Services</Link> &gt;{' '}
                        <span className="text-gray-200">{service.title}</span>
                    </nav>
                    <ServicesBlogView title={service.title} description={service.description} image={service.image} textColor={service.textColor} />
                </div>
            </section>
            <Form />
            <Footer />
        </main>
    );
};

export default Sentry.withProfiler(ServicesOverview);