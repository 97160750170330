"use client"; // For Next.js and React Server Components context

import React from 'react';
import BlogCard from './BlogCard'; // Ensure correct import path
import { agrB, retB, logB, helB, aitB, finB } from '../utils';

const blogData = [
  {
    id: 'iot-big-data-agriculture',
    image: agrB,
    title: "IoT & Big Data in Agriculture",
    description: "Explore how IoT and Big Data are revolutionizing modern agriculture. Learn how these technologies are enhancing productivity, optimizing resource use, and driving sustainable farming practices, transforming the agricultural landscape.",
    date: "Jan 5, 2024"
  },
  {
    id: 'custom-software-retail',
    image: retB,
    title: "Custom Software in Retail",
    description: "Discover the profound impact of custom software on the retail industry. Understand how bespoke solutions are revolutionizing customer experiences, streamlining operations, and enabling retailers to stay competitive in a rapidly evolving market.",
    date: "Feb 15, 2024"
  },
  {
    id: 'efficiency-logistics',
    image: logB,
    title: "Efficiency in Logistics",
    description: "Learn how custom software solutions are driving efficiency in logistics and supply chain management. From optimizing routes to managing inventory, see how technology is transforming the logistics industry for greater productivity and cost-effectiveness.",
    date: "Mar 10, 2024"
  },
  {
    id: 'advanced-tech-healthcare',
    image: helB,
    title: "Advanced Tech in Healthcare",
    description: "See how advanced software technologies are enhancing healthcare services and patient outcomes. Explore innovations in medical software that improve diagnostics, streamline patient care, and facilitate better healthcare management.",
    date: "Mar 25, 2024"
  },
  {
    id: 'ai-custom-software',
    image: aitB,
    title: "AI & Custom Software",
    description: "Understand how AI is transforming various industries through innovative custom software solutions. Discover the benefits and applications of AI in improving efficiency, decision-making, and competitive advantage across sectors.",
    date: "May 30, 2024"
  },
  {
    id: 'future-of-fintech',
    image: finB,
    title: "Future of Fintech",
    description: "Dive into the latest innovations in custom software development shaping the future of Fintech. Explore emerging trends, technologies, and solutions that are redefining financial services and creating new opportunities for growth.",
    date: "Jun 15, 2024"
  }
];

const BlogCardsContainer = () => {
  return (
    <section className="w-full min-h-screen overflow-hidden bg-zinc p-4">
      <div className="container mx-auto mt-16 mb-16">
        {/* Responsive grid: 1 column on small screens, 2 columns on medium screens, 3 columns on large screens */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-4">
          {blogData.map((blog) => (
            <BlogCard
              key={blog.id} // Add a unique key prop
              image={blog.image}
              title={blog.title}
              description={blog.description}
              id={blog.id} // Pass the id prop
              date={blog.date}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogCardsContainer;
