import React from 'react';

const ServicesBlogView = ({ title, description, image, textColor }) => {
    return (
        <section className="common-padding -mt-16 md:-mt-24 -mb-32 md:-mb-48">
            <div className="screen-max-width text-white">
                <img src={image} alt={title} className="mx-auto mb-6 w-full max-w-9xl rounded-xl border border-neutral-600" />
                <h1 className={`text-3xl sm:text-5xl md:text-6xl font-bold mb-5 md:mb-10 text-right ${textColor}`}>{title}</h1> {/* Use textColor */}
                <div className="sm:text-lg md:text-xl text-left max-w-4xl mx-auto">
                    {description.map((paragraph, index) => (
                        <p key={index} className="mb-5">{paragraph}</p>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ServicesBlogView;
