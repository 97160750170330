import React from 'react';
import { enrI, logI, insI, FinI, telI, agrI, autI, retI, eduI, medI, tecI, heaI } from '../utils';

// Define the Industries section component
const IndustriesSection = () => {
  // Sample data for industries
  const industries = [
    { title: "Finance & Banking", description: "Unlock Big Data’s potential with advanced analytics and Machine Learning tailored for financial institutions.", img: FinI },
    { title: "Healthcare", description: "Enhance patient care and efficiency in the medical sector with our innovative software solutions.", img: heaI },
    { title: "Insurance", description: "Boost efficiency and safety with cutting-edge software tailored for the insurance industry.", img: insI },
    { title: "Agriculture", description: "Optimize production and balance supply with our software solutions designed for the agriculture sector.", img: agrI },
    { title: "Logistics", description: "Streamline your supply chain, fleet, and warehousing with our smart software solutions for logistics.", img: logI },
    { title: "Automotive", description: "Drive innovation in smart vehicle production with our specialized software solutions for the automotive industry.", img: autI },
    { title: "Retail", description: "Create enhanced customer journeys and streamline operations with our advanced retail software solutions.", img: retI },
    { title: "Energy", description: "Improve productivity and sustainability in the utility sector with our tailored software solutions for energy.", img: enrI },
    { title: "Government & Education", description: "Deliver transparent and data-driven public services with our software solutions for government and education.", img: eduI },
    { title: "Media & Entertainment", description: "Engage audiences with unique, on-demand experiences through our tailored media and entertainment software.", img: medI },
    { title: "Technology", description: "Lead the market with innovative technology solutions designed to tackle industry-specific challenges.", img: tecI },
    { title: "Telecommunications", description: "Enhance connectivity and communication with our cutting-edge software solutions for telecommunications.", img: telI }
];

  return (
    <section id="industries" className="w-screen overflow-hidden h-full common-padding bg-gradient-to-b from-neutral-900 to-black text-white">
      <div className="screen-max-width">
        <div className="relative text-center mb-12">
          <h2
            className="absolute inset-0 text-4xl sm:text-4xl md:text-6xl font-bold"
            style={{
              color: '#FFEB3B', // Vibrant yellow
              transform: 'translate(2px, 2px)',
              zIndex: 1,
            }}
          >
            Industries We Work With
          </h2>
          <h2
            className="absolute inset-0 text-4xl sm:text-4xl md:text-6xl font-bold"
            style={{
              color: '#00BCD4', // Vibrant cyan
              transform: 'translate(-2px, -2px)',
              zIndex: 2,
            }}
          >
            Industries We Work With
          </h2>
          <h2
            className="relative text-4xl sm:text-4xl md:text-6xl font-bold text-black"
            style={{
              zIndex: 3,
            }}
          >
            Industries We Work With
          </h2>
        </div>
        <p className="text-center mb-12 text-xl sm:text-xl md:text-xl">
        From finance to healthcare, and agriculture to government, we empower a wide range of sectors with tailored technology solutions that drive transformation and growth. Explore how our expertise helps businesses thrive across diverse industries.
        </p>
        <div className="flex flex-wrap gap-8 justify-center">
  {industries.map((industry, index) => (
    <div
      key={index}
      className="relative bg-cover bg-center bg-gray-800 border border-neutral-600 rounded-lg flex flex-col max-w-xs w-full h-64 transition-transform transform hover:bg-gray-700 hover:scale-105"
      style={{ backgroundImage: `url(${industry.img})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/60 to-black/10 flex flex-col justify-start p-3">
        {/* Text on overlay */}
        <div className="text-white text-left">
          <h3 className="text-2xl font-bold mb-2">{industry.title}</h3>
          <p className="text-base">{industry.description}</p>
        </div>
      </div>
    </div>
  ))}
</div>

      </div>
    </section>
  );
};

export default IndustriesSection;
