import React, { useState, useEffect } from 'react';

// The ClickBoxGame component
const ClickBoxGame = () => {
  const [boxPosition, setBoxPosition] = useState({ x: window.innerWidth / 2 - 50, y: window.innerHeight / 2 - 50 });
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [boxColor, setBoxColor] = useState('white'); // State for box color

  useEffect(() => {
    if (!gameOver) {
      const intervalId = setInterval(moveBox, 500); // Move the box every 500ms
      return () => clearInterval(intervalId);
    }
  }, [gameOver]);

  const moveBox = () => {
    const newPosition = {
      x: Math.floor(Math.random() * (window.innerWidth - 100)),
      y: Math.floor(Math.random() * (window.innerHeight - 100)),
    };
    setBoxPosition(newPosition);
  };

  const handleClick = () => {
    if (!gameOver) {
      setScore(score + 1);
      setBoxColor('red'); // Change color to red
      setTimeout(() => setBoxColor('white'), 1000); // Revert color back to white after 1 second
      moveBox();
    }
  };

  return (
    <div>
      <h2 className='font-mono text-xl sm:text-2xl md:text-4xl text-blue'>404 <span className='text-amber-400'>|</span> Page Not Found</h2>
      <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          padding: '9px',
          backgroundColor: 'white',
          color: 'black',
          borderRadius: '15px',
          zIndex: 1000,
        }}
        className='text-3xl'
      >
        Score: {score}
      </div>
      <div
        onClick={handleClick}
        style={{
          position: 'absolute',
          width: '70px',
          height: '70px',
          backgroundColor: boxColor, // Use boxColor state
          borderRadius: '50%',
          left: `${boxPosition.x}px`,
          top: `${boxPosition.y}px`,
          cursor: 'pointer',
          transition: 'all 1.3s ease',
        }}
      />
      {gameOver && <button onClick={() => window.location.reload()}>Restart</button>}
    </div>
  );
};

// Main component for the 404 page
const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', color: 'white', backgroundColor: 'black', minHeight: '100vh', paddingTop: '50px' }}>
      <ClickBoxGame />
    </div>
  );
};

export default NotFound;
