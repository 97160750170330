"use client";
import React from 'react';

const MissionAboutUs = () => {
    // Define the inline SVG for the black and white vertical stripes with reduced opacity
    const bgPattern = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <defs>
          <pattern id="stripes" patternUnits="userSpaceOnUse" width="40" height="40">
            <line x1="0" y1="0" x2="0" y2="40" stroke="rgba(0, 0, 0, 0.3)" strokeWidth="4" />
            <line x1="20" y1="0" x2="20" y2="40" stroke="rgba(255, 255, 255, 0.15)" strokeWidth="4" />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#stripes)" />
      </svg>
    `;

    return (
        <section
            className="w-screen overflow-hidden h-full common-padding"
            style={{
                backgroundImage: `url('data:image/svg+xml;utf8,${encodeURIComponent(bgPattern)}')`,
                backgroundSize: '40px 40px', // Adjust size to match the pattern
                backgroundRepeat: 'repeat'
            }}
        >
            <div className="screen-max-width text-white z-50">
                <h2 className="text-7xl sm:text-7xl md:text-8xl lg:text-9xl font-bold mb-6 text-left text-white">
                    Our <span className="bg-gradient-to-r from-amber-400 via-sky-400 to-green-300 bg-clip-text text-transparent">Mission</span>
                </h2>
                <p className="text-lg md:text-xl lg:text-2xl">
                    <span className="block p-3 mb-5 mt-5 text-3xl sm:text-3xl md:text-5xl lg:text-5xl text-right font-bold">Delivering Excellence,<br />Driving Success</span>
                    <span className='block p-3 mb-6 text-justify text-neutral-400'>
                        “Our mission is to deliver innovative, reliable, and efficient software solutions that enable our clients to achieve their goals and drive their success. We are committed to excellence in every project we undertake, ensuring the highest quality and value through our expertise, dedication, and collaborative approach. By fostering strong partnerships and embracing the latest technologies, we aim to create lasting impact and contribute to the growth and success of businesses worldwide.”
                    </span>
                    <div className="p-3 text-neutral-200 ml-5">
                        <ul className="list-disc list-inside space-y-2">
                            <li className="text-lg md:text-xl lg:text-2xl">Innovate and provide cutting-edge software solutions.</li>
                            <li className="text-lg md:text-xl lg:text-2xl">Ensure reliability and efficiency in every project.</li>
                            <li className="text-lg md:text-xl lg:text-2xl">Deliver exceptional value through our expertise and dedication.</li>
                            <li className="text-lg md:text-xl lg:text-2xl">Foster strong partnerships and collaborative approaches.</li>
                            <li className="text-lg md:text-xl lg:text-2xl">Embrace the latest technologies to drive growth and success.</li>
                        </ul>
                    </div>
                </p>
            </div>
        </section>
    );
};

export default MissionAboutUs;
