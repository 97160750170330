"use client";
import { TextGenerateEffect } from "./ui/text-generate-effect";
import React, { useState } from 'react';

const quote = '"The future is yours to design—start now."';
const quote_by = '– Yeheshan Thamara, Founder & CEO of XnorY';

const QuoteComponent = () => {
  const [isFirstAnimationComplete, setIsFirstAnimationComplete] = useState(false);

  return (
    <section className="flex flex-col items-center justify-center p-6 bg-white text-center">
      <blockquote>
        <TextGenerateEffect
          words={quote}
          className="text-lg sm:text-xl md:text-5xl lg:text-5xl font-serif italic text-black"
          onComplete={() => setIsFirstAnimationComplete(true)}
        />
      </blockquote>
      <div className="w-full flex justify-center mt-4">
        {/* Placeholder space */}
        {!isFirstAnimationComplete && (
          <cite className="text-sm sm:text-base font-semibold text-white">
            <span>{quote_by}</span>
          </cite>
        )}
        {isFirstAnimationComplete && (
          <cite>
            <TextGenerateEffect
              words={quote_by}
              className="text-sm sm:text-base font-semibold text-gray-600"
            />
          </cite>
        )}
      </div>
    </section>
  );
};

export default QuoteComponent;
